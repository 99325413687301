///Import react section
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'
import { Translate } from 'react-localize-redux'

/** Material-UI imports section */
/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout'
import Toaster from '../common/Toaster'
import CreateDesktopView from './create/CreateDesktop'
/**Import actions section */

import { createSurveysData } from '../../store/survey/SurveyActions'
import { loadSurveyTemplatesPaginatedFromBackEnd } from '../../store/surveyTemplates/SurveyTemplatesActions'
import { toSafeObject } from '../../store/helpers/ListHelper'
import LoadingPage from '../common/LoadingPage'

const Create = (props) => {
	/**Render hooks section */
	const dispatch = useDispatch()

	/**Redux store information section*/
	
	const isLoading = useSelector((state) => state.surveyTemplates.get('isLoading'))
	const isSaving = useSelector((state) => state.surveys.get('isSaving'))

	const surveyTemplatesPaginated = useSelector((state) =>
		state.surveyTemplates.get('surveyTemplatesPaginated') ? toSafeObject(state.surveyTemplates.get('surveyTemplatesPaginated')) : null
	)

	const [toasterMessageConfig, setToasterMessageConfig] = useState({
		showToaster: false,
		toasterMessage: null,
		toasterVariant: '',
		name: '',
	})

	/**Use effect section */
	useEffect(() => {
		let paramsData = {
			rowsPerPage: 1000,
		}
		dispatch(loadSurveyTemplatesPaginatedFromBackEnd(paramsData))

	}, [dispatch])

	const onCreateSurvey = (surveyData) => {
		return dispatch(createSurveysData(surveyData))
			.then(() => {
				props.history.push(`/survey`)
				setToasterMessageConfig({
					showToaster: true,
					toasterVariant: 'success',
					toasterMessage: 'common.removeCorrectly',
				})
			})
			.catch(() => {
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage: 'common.error',
					toasterVariant: 'error',
				})
			})
	}
    if (isLoading) return <LoadingPage />

	return (
		<>
			<CreateDesktopView
				onCreateSurvey={onCreateSurvey}
				//arrays
				surveyTemplates={surveyTemplatesPaginated ? surveyTemplatesPaginated.items:[]}
                //objects
                isSaving={isSaving}
			/>
			<Toaster
				message={<Translate id={toasterMessageConfig.toasterMessage} />}
				open={toasterMessageConfig.showToaster}
				variant={toasterMessageConfig.toasterVariant}
				onClose={() => {
					setToasterMessageConfig({
						showToaster: false,
						toasterMessage: '',
						toasterVariant: '',
					})
				}}
			/>
		</>
	)
}

const styles = (theme) => ({})

export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme: true })(Create)))
