///Import react section
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import { Translate } from 'react-localize-redux'
import queryString from 'query-string'
/** Material-UI imports section */
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import AddIcon from '@material-ui/icons/AddTwoTone'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import BiddingIcon from '@material-ui/icons/ViewCarousel'
import Fab from '@material-ui/core/Fab'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout'
import DataTable from '../common/DataTable'
import LoadingPage from '../common/LoadingPage'
import Toaster from '../common/Toaster'
import SearchInput from '../common/SearchInput/SearchInput'
/**import actions section */
import CreateSurveyTemplatePanel from './components/index/CreateSurveyTemplatePanel'
// import { loadAllBiddingsFromBackEnd } from '../store/biddings/BiddingsActions'
import { loadSurveyTemplatesPaginatedFromBackEnd } from '../../store/surveyTemplates/SurveyTemplatesActions'
/**import helper section */
import { converListToArray, toSafeObject } from '../../store/helpers/ListHelper'
import { ROLE_COMPANYADMINISTRATOR, IsUserInRole, CaBeShowListSuppliers } from '../../store/helpers/RolesHelper'
import PopperListsComponent from '../biddings/components/PopperListsComponent'
import { IsCurrentActiveDirectoryUser } from '../../store/helpers/SessionHelper'
import { Chip } from '@material-ui/core'

const IndexView = (props) => {
	const { classes } = props
	/**Render hooks section */
	// const classes = styles()
	const dispatch = useDispatch()
	let location = useLocation()
	let history = useHistory()
	const queryParams = queryString.parse(location.search)

	/**Redux store information section*/
	const userRolesByCompany = useSelector((state) =>
		state.profile.get('userRolesByCompany') ? converListToArray(state.profile.get('userRolesByCompany')) : []
	)
	const isLoading = useSelector((state) => state.surveyTemplates.get('isLoading'))
	const isSaving = useSelector((state) => state.surveyTemplates.get('isSaving'))

	// const usersByRole = useSelector((state) =>
	// 	state.internalUsers.get('usersByRole') ? converListToArray(state.internalUsers.get('usersByRole')) : []
	// )
	// const internalUsers = useSelector((state) =>
	// 	state.internalUsers.get('internalUsers') ? converListToArray(state.internalUsers.get('internalUsers')) : []
	// )
	// const userProfile = useSelector((state) =>
	// 	state.oidc.user ? state.oidc.user.profile : { name: '', email: '', user_name: '' }
	// )

	const surveyTemplatesPaginated = useSelector((state) =>
		state.surveyTemplates.get('surveyTemplatesPaginated')
			? toSafeObject(state.surveyTemplates.get('surveyTemplatesPaginated'))
			: null
	)

	const userProfileLogged = useSelector((state) => state.oidc.user)

	const [toasterMessageConfig, setToasterMessageConfig] = useState({
		showToaster: false,
		toasterMessage: null,
		toasterVariant: '',
		name: '',
	})

	const [createSurveyPanel, setCreateSurveyPanel] = useState(false)

	const onOpenClosePanelCreateSurveyTemplate = () => {
		setCreateSurveyPanel(!createSurveyPanel)
	}
	// /**Use effect section */
	useEffect(() => {
		dispatch(loadSurveyTemplatesPaginatedFromBackEnd())
	}, [dispatch])

	/**Use effect section */
	useEffect(() => {
		const queryParams = queryString.parse(location.search)
		dispatch(loadSurveyTemplatesPaginatedFromBackEnd(queryParams))
	}, [dispatch, location.search])

	const onChangeSearchValue = (searchQuery) => {
		const queryParams = queryString.parse(location.search)
		if (searchQuery) queryParams.query = searchQuery
		else delete queryParams.query
		history.push({ search: queryString.stringify(queryParams) })
	}

	const getCounterMessage = () => {
		if (surveyTemplatesPaginated.items) {
			let totalBiddings = surveyTemplatesPaginated.items.length
			return (
				<div>
					{<Translate id='common.showing' />}
					<span className={classes.showingCounter}>{totalBiddings}</span>&nbsp;
					{<Translate id='common.of' />}
					<span className={classes.showingCounter}>{surveyTemplatesPaginated.total}</span>&nbsp;
					{<Translate id='common.records' />}
				</div>
			)
		}
	}

	/**
	 * On change sort criteria
	 *
	 * @param {*} sortBy
	 * @param {*} sortAscending
	 * @memberof Index
	 */
	const onChangeSortCriteria = (sortBy, sortAscending) => {
		const queryParams = queryString.parse(location.search)
		queryParams.sortBy = sortBy
		queryParams.sortDirection = sortAscending === true ? 'asc' : 'desc'
		history.push({ search: queryString.stringify(queryParams) })
	}

	const onChangePage = (page, rowsPerPage) => {
		const queryParams = queryString.parse(location.search)
		if ((queryParams && queryParams.rowsPerPage ? queryParams.rowsPerPage : 20) === rowsPerPage) {
			queryParams.pageNumber = page
		}
		queryParams.rowsPerPage = rowsPerPage
		history.push({ search: queryString.stringify(queryParams) })
	}

	// /**
	//  * onSeeSurveyTemplate
	//  */
	// const onSeeSurveyTemplate = (item) => {
	// 	props.history.push(`/surveyTemplate/${item.Id}`)
	// }
	const onRowClick = (item, event) => {
		let tagName = event.target.tagName
		if (tagName !== 'svg' && tagName !== 'A') {
			props.history.push(`/surveyTemplate/${item.Id}`)
		}
	}

	/**
	 * On render cell item custom
	 * @param {*} dataSource
	 * @param {*} item
	 */
	const onRenderCellItem = (dataSource, item) => {
		if (dataSource === 'Title') {
			return (
				<Typography
					style={{
						maxWidth: '200px',
						textOverflow: 'ellipsis',
						whiteSpace: 'nowrap',
						overflow: 'hidden',
					}}
				>
					{item.Title}
				</Typography>
			)
		}
		if (dataSource === 'Description') {
			return (
				<Typography
					style={{
						maxWidth: '200px',
						textOverflow: 'ellipsis',
						whiteSpace: 'nowrap',
						overflow: 'hidden',
					}}
				>
					{item.Description}
				</Typography>
			)
		}

		if (dataSource === 'Percentage') {
			return (
				<Chip
					// icon={<AddIcon />}
					label={`${item.Percentage}%`}
					style={{
						backgroundColor: getTotalPercentajeChipColor(item.Percentage),
						color: '#fff',
						fontWeight: 'bold',
					}}
					size='medium'
				/>
			)
		}

		if (dataSource === 'Options' && IsCurrentActiveDirectoryUser(userProfileLogged)) {
			let buttonsForMoreCard = []

			buttonsForMoreCard.push({
				title: <Translate id='biddingsModule.duplicated' />,
				icon: <EditIcon />,
				// onClick: (event) => {
				// 	onDuplicatedBiddingOpenCloseLateralPanel(event, item)
				// },
				isIconMaterial: true,
				disabled: isSaving,
			})

			buttonsForMoreCard.push({
				title: <Translate id='common.delete' />,
				// onClick: (event) => {
				// 	onRemoveBidding(event, item.Id)
				// },
				icon: <DeleteIcon />,
				isIconMaterial: true,
				// disabled:
				// 	!canBeEditBiddingInStatusDraftIndex(item, userRolesByCompany, userProfile, status) || isSaving,
			})

			return <PopperListsComponent title={<Translate id='common.options' />} listItems={buttonsForMoreCard} />
		}
	}

	/**
	 * Get the card content
	 *
	 * @memberof Index
	 */
	const getCardContent = () => {
		if (isLoading === true) {
			return <LoadingPage />
		} else {
			return (
				<>
					{/* <SurveyList
						//Array
						surveys={surveyTemplatesPaginated ? surveyTemplatesPaginated.items : []}
						//Functions
						onSeeSurveyTemplate={onSeeSurveyTemplate}
					/> */}
					<DataTable
						data={surveyTemplatesPaginated ? surveyTemplatesPaginated.items : []}
						configuration={TableConfiguration(CaBeShowListSuppliers(userRolesByCompany))}
						onRenderCellItem={onRenderCellItem}
						onChangeSortCriteria={onChangeSortCriteria}
						onRowClick={onRowClick}
						isIndex
						showFilters
						totalRows={surveyTemplatesPaginated.items.length}
						sortBy={queryParams && queryParams.sortBy ? queryParams.sortBy : 'FormatedFolio'}
						sortAscending={
							queryParams && queryParams.sortDirection
								? queryParams.sortDirection === 'desc'
									? false
									: true
								: false
						}
						page={queryParams && queryParams.pageNumber ? parseInt(queryParams.pageNumber) : 0}
						rowsPerPage={queryParams && queryParams.rowsPerPage ? queryParams.rowsPerPage : 20}
						onChangePage={(newPage, newRowsPerPage) => {
							onChangePage(newPage, newRowsPerPage)
						}}
					/>
				</>
			)
		}
	}

	const getTotalPercentajeChipColor = (percentage) => {
		if (percentage > 75) return '#4caf50' // Verde
		if (percentage > 50) return '#ffeb3b' // Amarillo
		return '#f44336' // Rojo
	}

	return (
		<div>
			<CardHeader
				avatar={
					<Avatar>
						<BiddingIcon />
					</Avatar>
				}
				action={
					<div style={{ display: 'flex' }}>
						<div style={{ marginRight: '5px' }}>
							<SearchInput
								onChangeSearchValue={onChangeSearchValue}
								value={!!queryParams && queryParams.query ? queryParams.query : ''}
							/>
						</div>

						{IsUserInRole(ROLE_COMPANYADMINISTRATOR, userRolesByCompany) && (
							<Fab size='small' color='primary' onClick={onOpenClosePanelCreateSurveyTemplate}>
								<AddIcon />
							</Fab>
						)}
					</div>
				}
				title={<Typography variant='button'>{<Translate id='survey.template.title' />}</Typography>}
				subheader={getCounterMessage()}
			/>

			<CardContent>{getCardContent()}</CardContent>

			{createSurveyPanel && (
				<CreateSurveyTemplatePanel
					onCloseButtonClick={onOpenClosePanelCreateSurveyTemplate}
					setToasterMessageConfig={setToasterMessageConfig}
					isSaving={isSaving}
				/>
			)}
			<Toaster
				message={
					<Translate id={toasterMessageConfig.toasterMessage} data={{ name: toasterMessageConfig.name }} />
				}
				open={toasterMessageConfig.showToaster}
				variant={toasterMessageConfig.toasterVariant}
				onClose={() => {
					setToasterMessageConfig({
						showToaster: false,
						toasterMessage: '',
						toasterVariant: '',
					})
				}}
			/>
		</div>
	)
}

function TableConfiguration(isDisabled) {
	let columns = [
		{
			header: <Translate id='biddingsModule.table.creationDate' />,
			dataSource: 'CreationDate',
			isSortable: true,
		},
		{
			header: 'Titulo',
			dataSource: 'Title',
			isSortable: true,
		},
		{
			header: 'Descripción',
			dataSource: 'Description',
			isSortable: true,
		},
		{
			header: 'Porcentaje',
			dataSource: 'Percentage',
			isSortable: true,
		},
		// {
		// 	header: 'Opciones',
		// 	dataSource: 'Options',
		// 	isSortable: true,
		// },
	]

	return { columns }
}

const styles = (theme) => ({
	avatar: {
		backgroundColor: theme.palette.secondary.main,
	},
	actionsToolbar: {
		display: 'flex',
	},
	searchInput: {
		marginTop: '3px',
		marginRight: '15px',
	},
	showingUsersCounter: {
		color: theme.palette.secondary.main,
	},
	showingCounter: {
		marginLeft: '5px',
	},
	biddingContainer: {
		maxHeight: `calc(100vh - ${275}px)`,
		minHeight: `calc(100vh - ${275}px)`,
		overflowY: 'hidden',
		backgroundColor: 'red',
	},
	categoryIcon: {
		marginLeft: '5px',
	},
})

export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme: true })(IndexView)))
