import React, { useEffect, useState } from 'react'
import propTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import {
	DialogContentText,
	DialogTitle,
	Divider,
	Dialog,
	withStyles,
	Typography,
	Button,
	Chip,
} from '@material-ui/core'
import DatePicker from '../../common/DateTimePicker/DatePicker'
import Select from 'react-select'
import { useDispatch } from 'react-redux'
import {
	VerifyDisponibleTimeWineCellar,
	AddWineCellarDate,
	chooseWinning,
} from '../../../store/transportTender/transportTenderAction'
import moment from 'moment'
import { Translate } from 'react-localize-redux'
import { Add, ErrorOutline, InfoOutlined } from '@material-ui/icons'

const AddRequiredWineCellarDate = (props) => {
	const {
		open,
		onClose,
		classes,
		transportTenderdetail,
		isLoading,
		openDialog,
		setToasterMessageConfig,
		onReloadDetail,
	} = props
	const dispatch = useDispatch()
	const [selectDate, setSelectDate] = useState(moment(transportTenderdetail.DateServiceRequest))
	const [timeOptionSelected, setTimeOptionSelected] = useState(null)
	const [timeOptions, setTimeOptions] = useState([])
	const [hoursWineCeller, setHoursWineCeller] = useState(null)
	const [isVerifed, setVerify] = useState(false)
	const [startTime, setStartTime] = useState(null)
	const [endTime, setEndTime] = useState(null)
	const [isWineCellarDateAssing, setWinerCellarDate] = useState(true)
	const VerifyDisponibleTime = () => {
		dispatch(
			VerifyDisponibleTimeWineCellar(selectDate, transportTenderdetail.WineCellar.name, transportTenderdetail.Id)
		).then((response) => {
			if (response) {
				const timesEnable = response.filter((time) => !time.disabled)
				setTimeOptions(timesEnable)
				setVerify(true)
				setHoursWineCeller(null)
				setTimeOptionSelected(null)
			}
		})
	}
	const addRequiredWineCellarDate = () => {
		dispatch(AddWineCellarDate(transportTenderdetail.Id, selectDate, startTime, endTime)).then(() => onClose())
	}
	const chooseWinningAndAddRequiredWineCellarDate = () => {
		if (isWineCellarDateAssing)
			return dispatch(AddWineCellarDate(transportTenderdetail.Id, selectDate, startTime, endTime))
				.then((_) => {
					dispatch(chooseWinning(openDialog.proposalId, openDialog.status, transportTenderdetail.Id)).catch(
						(err) => {
							setToasterMessageConfig({
								showToaster: true,
								toasterMessage: 'Error to winner establish ',
								toasterVariant: 'error',
							})
						}
					)
				})
				.catch((err) => {
					let message = 'carrierTenders.error.errorEditingTender'
					if (err) {
						message = err
					}
					setToasterMessageConfig({
						showToaster: true,
						toasterMessage: <Translate id={message} />,
						toasterVariant: 'error',
					})
				})
				.finally(() => {
					onReloadDetail()
					onClose()
				})
	}
	const generarOpcionesDeTiempo = () => {
		const opciones = []
		for (let horas = 0; horas <= 24; horas++) {
			for (let minutos = 0; minutos < 60; minutos += 30) {
				const valorEnMinutos = horas * 60 + minutos
				if (valorEnMinutos > 0 && valorEnMinutos <= 1440) {
					const hora = `${horas.toString().padStart(2, '0')}:${minutos.toString().padStart(2, '0')}`
					opciones.push({ value: valorEnMinutos, label: hora })
				}
			}
		}
		return opciones
	}
	const sumarMinutosAHora = () => {
		if (!timeOptionSelected && !hoursWineCeller) return ''

		const momentFecha = timeOptionSelected.horaActual ? moment.utc(timeOptionSelected.horaActual) : moment.utc()
		let fechaHoraCopia = moment.utc(momentFecha)

		const hour = Math.floor(hoursWineCeller / 60)
		const minutes = hoursWineCeller % 60

		fechaHoraCopia = fechaHoraCopia.add(hour, 'hours')
		fechaHoraCopia = fechaHoraCopia.add(minutes, 'minutes')

		return fechaHoraCopia.format('DD/MM/YYYY HH:mm a')
	}
	const optionsTime = generarOpcionesDeTiempo()
	const calculateHourEnd = sumarMinutosAHora()

	useEffect(() => {
		if (timeOptionSelected && calculateHourEnd) {
			const horaStartSelected = timeOptionSelected.horaActual
				? moment.utc(timeOptionSelected.horaActual).format('HH:mm:ss')
				: moment.utc().format('HH:mm:ss')
			const horaEndSelected = calculateHourEnd
				? moment.utc(calculateHourEnd, 'DD/MM/YYYY HH:mm').format('HH:mm:ss')
				: moment.utc().format('HH:mm:ss')

			setStartTime(horaStartSelected)
			setEndTime(horaEndSelected)
		}
	}, [timeOptionSelected, calculateHourEnd])
	return (
		<Dialog open={open || openDialog.open} onClose={onClose} aria-labelledby='form-dialog-title'>
			<DialogTitle>
				{openDialog.open ? (
					<Typography variant='title'>Selecciona el transportista ganador</Typography>
				) : (
					<Typography variant='title'>
						<Translate id={'carrierTenders.detail.dateInWineCellar.title'} />
					</Typography>
				)}
			</DialogTitle>
			<Divider />
			<DialogContentText className={classes.dialogContent}>
				{openDialog.open && (
					<div>
						<Typography gutterBottom variant='caption' component='p'>
							<Translate id='carrierTenders.resultsTender.selectSupplier' /> {openDialog.supplier}
						</Typography>
						<div className={classes.helpTextChoose}>
							<Typography variant='caption'>
								<Translate id='carrierTenders.resultsTender.helpTextChooseWineCellar.helpText' />:{' '}
							</Typography>
							{moment(transportTenderdetail.StartTime).format('DD/MM/YYYY HH:mm A')} -{' '}
							{moment(transportTenderdetail.EndTime).format('HH:mm A')}
							<div className={classes.buttonActionChoose}>
								{isWineCellarDateAssing ? (
									<Button
										style={{
											backgroundColor: 'red',
											color: 'white',
										}}
										onClick={() => setWinerCellarDate(false)}
									>
										<Translate id='carrierTenders.resultsTender.helpTextChooseWineCellar.cancel' />
									</Button>
								) : (
									<Button
										className={classes.btnNewWineCellarDate}
										onClick={() => setWinerCellarDate(true)}
									>
										<Add />
										<Translate id='carrierTenders.resultsTender.helpTextChooseWineCellar.newAssing' />
									</Button>
								)}
							</div>
						</div>
					</div>
				)}
				{(isWineCellarDateAssing || !openDialog.open) && (
					<div>
						<Typography variant='body2'>
							<Translate id={'carrierTenders.detail.dateInWineCellar.title'} />
						</Typography>
						<Typography variant='caption'>
							<Translate id={'carrierTenders.detail.dateInWineCellar.textHelp'} />
						</Typography>
						<div className={classes.totalContent}>
							<Typography className={classes.textTotal} variant='caption'>
								<Translate id={'carrierTenders.deals.totalEquipment'} />:
							</Typography>
							<Chip className={classes.totalChip} label={transportTenderdetail.TotalMaterial} />
						</div>
						<div className={classes.selectDateContent}>
							<DatePicker
								opens={'left'}
								drops={'down'}
								onChangeRange={(event) => setSelectDate(moment(event).format('YYYY-MM-DD'))}
								startDate={
									selectDate
										? moment(selectDate, 'YYYY-MM-DD')
										: moment(transportTenderdetail.DateServiceRequest)
								}
							/>
							<Button
								className={classes.buttonAction}
								disabled={!selectDate || isLoading}
								onClick={VerifyDisponibleTime}
							>
								<Translate id={'carrierTenders.detail.dateInWineCellar.checkAvailability'} />
							</Button>
						</div>
						{selectDate && isVerifed ? (
							timeOptions.length > 0 ? (
								<div className={classes.dateContent}>
									<Typography variant='caption'>
										<Translate id={'carrierTenders.detail.dateInWineCellar.selectDateInfoText'} />
									</Typography>
									<div className={classes.selectContent}>
										<div className={classes.loadTime}>
											<Typography>
												<Translate id={'carrierTenders.detail.dateInWineCellar.loadTime'} />
											</Typography>
											<Select
												defaultValue={timeOptions[0]}
												options={timeOptions}
												fullWidth
												styles={{
													menu: (base) => ({ ...base, zIndex: 10, maxHeight: 200 }),
													menuList: (base) => ({
														...base,
														maxHeight: 200,
														paddingTop: 0,
													}),
													menuPortal: (base) => ({ ...base, zIndex: 9999 }), /// THIS IS TO SHOW MENU OVER MODAL
												}}
												menuPortalTarget={document.parentNode}
												menuPosition='fixed' //THIS IS REQUIRED IN MODAL!!
												menuPlacement={'auto'}
												onChange={(timeOption) => setTimeOptionSelected(timeOption)}
												isDisabled={!selectDate}
											/>
										</div>
										<div className={classes.selectSpace}>
											<Typography>
												<Translate
													id={'carrierTenders.detail.dateInWineCellar.hourinWineCellar'}
												/>
											</Typography>
											<Select
												defaultValue={optionsTime[0]}
												options={optionsTime}
												fullWidth
												styles={{
													menu: (base) => ({ ...base, zIndex: 10, maxHeight: 200 }),
													menuList: (base) => ({
														...base,
														maxHeight: 200,
														paddingTop: 0,
													}),
													menuPortal: (base) => ({ ...base, zIndex: 9999 }), /// THIS IS TO SHOW MENU OVER MODAL
												}}
												menuPortalTarget={document.parentNode}
												menuPosition='fixed' //THIS IS REQUIRED IN MODAL!!
												menuPlacement={'auto'}
												onChange={(event) => setHoursWineCeller(event.value)}
												isDisabled={!timeOptionSelected}
											/>
										</div>
										<div>
											<Typography>
												<Translate id={'carrierTenders.detail.dateInWineCellar.endDate'} />
											</Typography>
											<Typography>{calculateHourEnd}</Typography>
										</div>
									</div>
								</div>
							) : (
								<div className={classes.errorHelpInfo}>
									<ErrorOutline />
									<Typography className={classes.infoErrorTextHelp}>
										<Translate id={'carrierTenders.detail.dateInWineCellar.errorInfo'} />
									</Typography>
								</div>
							)
						) : (
							<div className={classes.warningHelpInfo}>
								<InfoOutlined />
								<Typography className={classes.infoTextHelpInfo}>
									<Translate id={'carrierTenders.detail.dateInWineCellar.infoHelpText'} />
								</Typography>
							</div>
						)}
					</div>
				)}

				<div className={classes.areaButton}>
					<Button onClick={onClose}>
						<Translate id={'carrierTenders.detail.dateInWineCellar.close'} />
					</Button>
					{openDialog.open ? (
						<Button
							className={classes.buttonAction}
							onClick={() => chooseWinningAndAddRequiredWineCellarDate()}
							disabled={
								(!selectDate || isLoading || !hoursWineCeller || !calculateHourEnd) &&
								isWineCellarDateAssing
							}
						>
							Guardar ganador
						</Button>
					) : (
						<Button
							className={classes.buttonAction}
							onClick={() => addRequiredWineCellarDate()}
							disabled={!selectDate || isLoading || !hoursWineCeller || !calculateHourEnd}
						>
							<Translate id={'carrierTenders.detail.dateInWineCellar.accept'} />
						</Button>
					)}
				</div>
			</DialogContentText>
		</Dialog>
	)
}
AddRequiredWineCellarDate.propTypes = {
	open: propTypes.bool,
	onClose: propTypes.func,
}
AddRequiredWineCellarDate.defaultProps = {
	open: false,
	onClose: () => console.warn('Callback [onClose] no defined'),
}
const styles = (theme) => ({
	dialogContent: {
		padding: '8px',
	},
	selectDateContent: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	buttonAction: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.common.white,
		marginLeft: '8px',
	},
	areaButton: {
		margin: '8px 0px',
		display: 'flex',
		justifyContent: 'flex-end',
	},
	totalContent: {
		display: 'flex',
		alignItems: 'center',
		height: '2rem',
		color: '#757575',
		marginLeft: '8px',
	},
	totalChip: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.common.white,
		height: '1rem',
		marginLeft: '8px',
	},
	warningHelpInfo: {
		margin: '8px 0px',
		padding: '8px',
		backgroundColor: theme.palette.primary.light,
		borderRadius: '8px',
		display: 'flex',
		alignItems: 'center',
		color: theme.palette.background.default,
	},
	infoTextHelpInfo: {
		color: theme.palette.background.default,
		paddingLeft: '8px',
	},
	infoErrorTextHelp: {
		paddingLeft: '8px',
	},
	errorHelpInfo: {
		margin: '8px 0px',
		padding: '8px',
		backgroundColor: theme.palette.error.light,
		borderRadius: '8px',
		display: 'flex',
		alignItems: 'center',
	},
	dateContent: {
		paddingTop: '8px',
	},
	selectContent: {
		display: 'flex',
	},
	loadTime: {
		width: '17vw',
		paddingRight: '8px',
	},
	selectSpace: {
		paddingRight: '8px',
	},
	helpTextChoose: {
		width: '100%',
		backgroundColor: theme.palette.colors.blue,
		color: theme.palette.common.white,
		borderRadius: '8px',
		margin: '8px 0px',
		padding: '8px',
	},
	buttonActionChoose: {
		display: 'flex',
		justifyContent: 'center',
		padding: '8px',
	},
	btnNewWineCellarDate: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.common.white,
	},
})
export default withRouter(withStyles(styles, { withTheme: true })(AddRequiredWineCellarDate))
