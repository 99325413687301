import React, { useState } from 'react'
import moment from 'moment'
/** Translations imports section */
// Importaciones de Material-UI
import { Grid } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
// import PercentIcon from '@material-ui/icons/Percent';
import SupplierCard from './SupplierCard'
import SurveyCard from './SurveyCard'
import { IsNullOrEmpty } from '../../../store/helpers/StringHelper'
const CreateDesktopView = (props) => {
	const { isSaving, surveyTemplates } = props

	const { onCreateSurvey } = props
	const [surveyData, setSurveyData] = useState({
		suppliers: [],
		attachments: [],
		name: '',
		activationDate: '',
		expirationDate: '',
		acceptedBySupplier: false,
		optionAll: true,
		optionNationals: false,
		optionForeigns: false,
		startDate: null,
		endDate: null,
		surveyTemplateId: '',
	})

	const onUpdateProperty = (property, value) => {
		setSurveyData({
			...surveyData,
			[property]: value,
		})
	}

	const onChangeCheckBox = (property, value) => {
		if (property === 'optionNationals') {
			setSurveyData({
				...surveyData,
				[property]: value,
				optionForeigns: false,
				optionAll: false,
			})
		}
		if (property === 'optionForeigns') {
			setSurveyData({
				...surveyData,
				[property]: value,
				optionAll: false,
				optionNationals: false,
			})
		}
		if (property === 'optionAll') {
			setSurveyData({
				...surveyData,
				[property]: value,
				optionForeigns: false,
				optionNationals: false,
			})
		}
	}

	const onChangeSupplierList = (supplierSelected) => {
		let supplierToAdd = {
			Code: supplierSelected.code,
			Name: supplierSelected.name,
			Email: supplierSelected.email,
		}

		let existSupplierInList = surveyData.suppliers.find((x) => x.Code === supplierToAdd.Code)
		if (existSupplierInList) {
		} else {
			let newSupplierList = [...surveyData.suppliers]
			console.log('newSupplierList', newSupplierList)
			newSupplierList = newSupplierList.concat(supplierToAdd)
			setSurveyData({
				...surveyData,
				suppliers: newSupplierList,
			})
		}
	}

	const onRemoveSupplierList = (supplierCode) => {
		let newSupplierList = surveyData.suppliers.filter((x) => x.Code !== supplierCode)
		setSurveyData({
			...surveyData,
			suppliers: newSupplierList,
		})
	}

    const onChangeTemplate=(option)=>{
		setSurveyData({
			...surveyData,
			surveyTemplateId: option.value,
		})
    }

	const canBeSaved = () => {
		if (isSaving) return false
		else {
			if (IsNullOrEmpty(surveyData.name)) return false
			if (surveyData.suppliers.length <= 0) return false
			if (surveyData.activationDate.length === 0) return false
			if (surveyData.expirationDate.length === 0) return false
			if (moment(surveyData.expirationDate).isBefore(surveyData.activationDate)) return false
		}
		return true
	}

	return (
		<Grid container spacing={24}>
			<Grid item xs={6}>
				<SurveyCard
					//functions
					onUpdateProperty={onUpdateProperty}
					canBeSaved={canBeSaved}
					onCreateSurvey={onCreateSurvey}
                    onChangeTemplate={onChangeTemplate}
					//objects
					surveyData={surveyData}
					surveyTemplates={surveyTemplates}
					isSaving={isSaving}
				/>
			</Grid>
			<Grid item xs={6}>
				<SupplierCard
					//functions
					onUpdateProperty={onUpdateProperty}
					onChangeCheckBox={onChangeCheckBox}
					onChangeSupplierList={onChangeSupplierList}
					onRemoveSupplierList={onRemoveSupplierList}
					//objects
					surveyData={surveyData}
					suppliers={surveyData.suppliers ? surveyData.suppliers : []}
					//bools props section
					isSaving={isSaving}
				/>
			</Grid>
		</Grid>
	)
}

const styles = (theme) => ({})

export default withStyles(styles)(CreateDesktopView)
