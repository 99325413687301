import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'

/** Import component section */
import Home from './Index'
import Unauthorized from './Unauthorized'
import AcceptedNotices from './officialNotices/AcceptedNotices'
import EthicCodeQuestionnaires from './questionnaires/ethicCode/Questionnaires'

/** Import administration component section */
import WarehouseStaffIndex from './administration/WarehouseStaffIndex'
import IndexCompanies from './companies/Index'
import CompanyShow from './companies/Show'
import InternalUserIndex from './internalUser/Index'
import InterUserProfile from './internalUser/Profile'
import InternalUserShow from './internalUser/Show'
import ManualsPortal from './manuals/ManualsPortal'
import OfficialNoticeCreate from './officialNotices/Create'
import OfficialNoticeIndex from './officialNotices/Index'
import OfficialNoticeShow from './officialNotices/Show'
import SecurityCoursesDetail from './securityCourses/Detail'
import SecurityCoursesIndex from './securityCourses/Index'
import Certifications from './certifications/Index'
import AllCertifications from './certifications/AllCertifications.js'
import GetProfileSupplier from './suppliers/GetProfile'
import SupplierIndex from './suppliers/Index'
import SupplierRatings from './suppliers/RatingsView'
import SupplierRatingsIndirects from './suppliers/RatingsViewIndirects'
import SupplierShow from './suppliers/Show'
import ExternalUserIndex from './suppliers/users/ExternalUserIndex'
import ExternalUserShow from './suppliers/users/ExternalUserShow'
import ExternalUserProfile from './suppliers/users/Profile'
import TransportTenderdetail from './transportTender/Detail'
import TransportTenderIndex from './transportTender/Index'
import SurveyTemplatesIndex from './surveyTemplates/Index'
import SurveysIndex from './survey/Index'
import SurveysCreate from './survey/Create'
import SurveysShow from './survey/Show'
import ShowSupplier from './survey/ShowSupplier'
import SurveyTemplatesShow from './surveyTemplates/Show'

import ProspectCreate from './prospects/Create'
import ProspectsIndex from './prospects/Index'
import ProspectShow from './prospects/Show'
import SupplierContractsCreate from './supplierconstracts/Create'
import DocumentsEnviorementCreate from './supplierconstracts/CreateDocument'
import SupplierContracts from './supplierconstracts/Index'
import DocumentsEnviorements from './supplierconstracts/IndexDocuments'
import SupplierContractsRequest from './supplierconstracts/Request'
import SupplierContractAhow from './supplierconstracts/Show'
import DocumentsEnviorementAhow from './supplierconstracts/ShowDocument'
import AvailableSchedules from './transportTender/catalogs/AvailableSchedules'
import ManeuverCatalogIndex from './transportTender/catalogs/Maneuver'
import Rates from './transportTender/catalogs/Rates'
import RequirementsCarriers from './transportTender/catalogs/RequirementsCarriers'
import TransportType from './transportTender/catalogs/TransportType'
import TransportZone from './transportTender/catalogs/TransportZone'
import TypeShipment from './transportTender/catalogs/TypeShipment'
import WineCellar from './transportTender/catalogs/WineCellar'
import Clients from './transportTender/catalogs/clients'

import PlanningIndex from './planningFersa/Index'
import PlanningRelist from './planningFersa/Release'
import ShowDeliveryOrden from './planningFersa/Show'
import DeliveryOrderCreateFersa from './planningFersa/components/Create'
import DeliveryOrderRegisterFolioFersa from './planningFersa/components/RegisterArrival'
import ReleaseCalendarCreateFersa from './planningFersa/releaseCalendarLock/Create'
import ReleasecalendarFersa from './planningFersa/releaseCalendarLock/Index'
import ReleaseCalendarShowFersa from './planningFersa/releaseCalendarLock/show'
/** Import consults component section */
import ConsignmentQuery from './suppliers/queries/Consignments'
import InventoriesQuery from './suppliers/queries/Inventories'
import InventoryMaquilas from './suppliers/queries/InventoryMaquila'
import InvoicesQuery from './suppliers/queries/Invoices'
import PurchaseOrdersQuery from './suppliers/queries/PurchaseOrders'
import StocksQuery from './suppliers/queries/Stocks'

/** Import development component section */
import MaterialDeliveryCreate from './materialDelivery/Create'
import MaterialDeliveryIndex from './materialDelivery/Index'
import MaterialDeliveryShow from './materialDelivery/Show'
import MemoCreate from './memos/Create'
import MemosIndex from './memos/Index'
import MemoShow from './memos/Show'
import CancelNoticeContainer from './noticeChanges/CancelContainer'
import NoticeChangeCreate from './noticeChanges/Create'
import NoticeChangesIndex from './noticeChanges/Index'
import NoticeChangeShow from './noticeChanges/Show'
import ViewNotification from './noticeChanges/ViewNotification'
import CancelContainerQuote from './quotes/CancelContainer'
import QuotesIndex from './quotes/Index'
import ReminderContainer from './quotes/ReminderContainer'
import QuoteShow from './quotes/Show'
import CancelContainerSample from './samplerequests/CancelContainer'
import SampleRequestsIndex from './samplerequests/Index'
import ReminderContainerToSupplier from './samplerequests/ReminderContainerToSupplier'
import SampleRequestShow from './samplerequests/Show'

import AlternativeProvedureiaCreate from './alternativeProvedure/Create'
import AlternativeProvedureiaIndex from './alternativeProvedure/Index'
import AlternativeProvedureShow from './alternativeProvedure/Show'
import CancelContainer from './correctiveActions/CancelContainer'
import SacsCreate from './correctiveActions/Create'
import SacsIndex from './correctiveActions/Index'
import SacsShow from './correctiveActions/Show'
import PartNumberHistory from './partNumber/PartNumberHistory'
import PriceChangeRequestCreate from './priceChangeRequest/Create'
import RequestPriceChangeDetail from './priceChangeRequest/Detail'
import PriceChangeRequestIndex from './priceChangeRequest/Index'
import Qualifications from './qualifications/Index'
import GenerateRatingFERSA from './ratings/GenerateRatingFersa'
import GenerateRatingPtm from './ratings/GenerateRatingPtm'
import GenerateRatings from './ratings/GenerateRatings'
import ImportRating from './ratings/ImportRating'
import SparePartPriceChangeIndex from './sparePartPrice/Index'
import SparePartPricechangeShow from './sparePartPrice/Show'

/** Import quality component section */
import CancelContainerDevolution from './devolutions/CancelContainer'
import DevolutionsCreate from './devolutions/Create'
import DevolutionsIndex from './devolutions/Index'
import DevolutionShow from './devolutions/Show'
import NonConformityIndex from './qualityCatalogs/NonConformity'
import UsersQualityIndex from './qualityCatalogs/UsersQuality'
import CancelContainerQualityFailure from './qualityFailureReport/CancelContainer'
import QualityFailureReportCreate from './qualityFailureReport/Create'
import QualityFailureReportEdit from './qualityFailureReport/EditReport'
import QualityFailureReportIndex from './qualityFailureReport/Index'
import QualityFailureSendNotification from './qualityFailureReport/SendNotification'
import QualityFailureReportShow from './qualityFailureReport/Show'
import QualityFailureReportDevolution from './qualityFailureReport/components/CreateDevolution'
import QualityFailureReportRework from './qualityFailureReport/components/CreationRework'
import QualityFailureReportDeviation from './qualityFailureReport/components/RegisterDeviation'
import QualityAuditIndex from './qualityaudit/Index'
import QualityAuditShow from './qualityaudit/Show'
import CancelContainerRawMaterial from './rawmaterialnonconformity/CancelContainer'
import RawMaterialNonConformityCreate from './rawmaterialnonconformity/Create'
import CreateRawMaterialFromDelivery from './rawmaterialnonconformity/CreateRawMaterialFromDelivery'
import RawMaterialNonConformityEdit from './rawmaterialnonconformity/EditReport'
import RawMaterialNonConformityIndex from './rawmaterialnonconformity/Index'
import RawMaterialRegisterDeviation from './rawmaterialnonconformity/RegisterDeviation'
import RawMaterialSendNotification from './rawmaterialnonconformity/SendNotification'
import RawMaterialNonConformityShow from './rawmaterialnonconformity/Show'
import RawMaterialCreateDevolution from './rawmaterialnonconformity/components/CreationDevolution'
import RawMaterialCreateRework from './rawmaterialnonconformity/components/CreationRework'
import ReworksCreate from './reworks/Create'
import ReworksIndex from './reworks/Index'
import ReworksShow from './reworks/Show'
import ReworksDevolution from './reworks/components/CreateDevolution'
import ReworksReworkReport from './reworks/components/ReworkReport'
import CancelContainerSacQuality from './sacsquality/CancelContainer'
import SacsQualityCreate from './sacsquality/Create'
import SacsQualityIndex from './sacsquality/Index'
import SacsQualityShow from './sacsquality/Show'
import SacsQualityActionsSupplier from './sacsquality/components/CreateActionsBySupplier'
import SacsQualitySendNotification from './sacsquality/components/SendNotification'
import WarrantyReportCancel from './warrantyReportsPtm/CancelContainer'
import WarrantyReportCreate from './warrantyReportsPtm/Create'
import WarrantyReportIndex from './warrantyReportsPtm/Index'
import WarrantyReportShow from './warrantyReportsPtm/Show'

/** Import shipping orders component section */
import ShippingOrdersIndex from './shippingOrders/Index'
import ShippingOrdersShow from './shippingOrders/Show'

/** Import freight component section */
import FreightIndex from './freight/Index'
import FreightShow from './freight/Show'

/** Import warehouse raw material component section */
import WarehouseRawMaterialCreate from './warehouseRawMaterial/Create'
import WarehouseRawMaterialIndex from './warehouseRawMaterial/Index'
import WarehouseRawMaterialShow from './warehouseRawMaterial/Show'

/** Import guarantee component section */
import CustomerGuaranteeIndex from './customerGuarantee/Index'
import FailureGuaranteeIdex from './failureGuarantee/Index'
import GuaranteeReportCancel from './guaranteeReport/CancelContainer'
import GuaranteeReportCreate from './guaranteeReport/Create'
import GuaranteeReportEdit from './guaranteeReport/EditReport'
import GuaranteeReport from './guaranteeReport/Index'
import GuaranteeReportShow from './guaranteeReport/Show'
import AddNoteCredit from './guaranteeReport/components/AddNoteCredit'
import GuaranteeSendNotification from './guaranteeReport/components/SendNotificationView'

/** Import Contractors containers section */
import AccessControlRegisters from './contractors/AccessContractor'
import EmployeesIndex from './contractors/employees/Index'
import EmployeeShow from './contractors/employees/Show'
import EmployeeCardsShow from './contractors/employees/ShowEmployeesCards'
import MedicalCheckIndex from './contractors/medicalcheck/Index'
import MedicalCheckShow from './contractors/medicalcheck/Show'
import ServiceProjectsIndex from './contractors/serviceProjects/Index'
import ServiceProjectsIndexSupplier from './contractors/serviceProjects/IndexSupplier'
import ServiceProjectShow from './contractors/serviceProjects/Show'
import SocialInsurancePaymentsIndex from './contractors/socialSecurityPayments/Index'
import SocialInsurancePaymentShow from './contractors/socialSecurityPayments/Show'

/** Import Planning selaction */
import DeliveryOrderCreate from './planning/deliveryOrder/Create'
import DeliveryOrderIndex from './planning/deliveryOrder/Index'
import DeliveryOrderRegisterFolio from './planning/deliveryOrder/RegisterArrival'
import DeliveryOrderShow from './planning/deliveryOrder/Show'
import ReleaseCalendarCreate from './planning/releaseCalendarLock/Create'
import ReleaseCalendarLock from './planning/releaseCalendarLock/Index'
import ReleaseCalendarShow from './planning/releaseCalendarLock/Show'
import ReleasePlan from './planning/releasePlan/ReleasePlan'
import ReleasePlanUpload from './planning/releasePlan/UpdateRelease'
import TranposrtDestinations from './planning/transportDestination/Index'

/** Import questionnaries component section */
import EthicCodeCreate from './questionnaires/ethicCode/Create'
import EthicCodeIndex from './questionnaires/ethicCode/Index'
import EthicCodeShow from './questionnaires/ethicCode/Show'

/** Import phone directory views component section */
import PhoneDirectoryIndex from './phoneDirectories/Index'
/** Import sustainability questionnaires containers section */
import CancelContainerSustainability from './sustainability/CancelContainer'
import SustainabilityCreate from './sustainability/Create'
import SustainabilityIndex from './sustainability/Index'
import SustainabilityShow from './sustainability/Show'

import SustainabilityIndexList from './sustainability2/Index'
import SustainabilityShowV2 from './sustainability2/Show'
import SustainabilityCalendar from './sustainability2/calendar/Show'
import SustainabilityTemplate from './sustainability2/templated/Template'

/** Import contact suppliers component section */
import ContactSupplierCreate from './contactsupplier/Create'
import ContactSupplierIndex from './contactsupplier/Index'
import ContactSupplierShow from './contactsupplier/Show'
import UpdateContactSupplier from './contactsupplier/UpdateContact'

/** Import expedient suppliers component section */
import ExpedientSupplierCreate from './expedientSupplier/Create'
import ExpedientsBySupplierIndex from './expedientSupplier/ExpedientsSupplier'
import ExpedientSupplierIndex from './expedientSupplier/Index'
import ExpedientSupplierShow from './expedientSupplier/Show'
import ShowExpedientBySupplier from './expedientSupplier/ShowExpedientBySupplier'

import QuestionnaireCalendarIndex from './questionnaires/questionnairesptm/CalendarIndex'
import QuestionnaireCreate from './questionnaires/questionnairesptm/Create'
import QuestionnaireIndex from './questionnaires/questionnairesptm/Index'
import QuestionnaireShow from './questionnaires/questionnairesptm/Show'
import TemplatesShow from './questionnaires/questionnairesptm/ShowTemplate'
import TemplatesIndex from './questionnaires/questionnairesptm/Templates'

/** Import accredited workshops containers section */
import AccreditedWorkshopsIndex from './accreditedWorkshops/IndexRouter'
//import AccreditedWorkshopsShow from './accreditedWorkshops/Show';
import PaymentRequestView from './accreditedWorkshops/paymentRequests/View'

/**Import biddings containers section */
import BiddingsBaseCreate from './biddings/CreateBase'
import BiddingsIndex from './biddings/Index'
import BiddingsShow from './biddings/Show'

import MaquilaOrdersIndex from './maquilaOrders/Index'
import MaquilaShow from './maquilaOrders/Show'

/**Import indirect qualifications container sections*/
import IndirectQualificationsIndex from './indirectqualifications/Index'
import IndirectQualificationsShow from './indirectqualifications/Show'
import IndirectQualificationsShowActionPlant from './indirectqualifications/ShowActionPlant'

/**Import billings containers section */
import BillingsIndex from './billings/Index'
import BillingsShow from './billings/Show'

/**Import billings containers section */
import WarehouseWindowsCalendarIndex from './warehouseWindows/CalendarIndex'
import WarehouseWindowsIndex from './warehouseWindows/Index'

/** Import accredited workshops containers section */
import ConsignmentNoteIndex from './consignmentNote/Index'
import ConsignmentNoteShow from './consignmentNote/Show'
import IndexFerryCuponManager from './consignmentNote/IndexFerryCuponManager'

/**Import delivery qualifications section */
import DeliveryQualificationsIndex from './deliveryQualifications/Index'
import DeliveryQualificationsShow from './deliveryQualifications/Show'

import * as moduleNames from '../store/helpers/ModuleNames'
import ModuleRoute from './common/ModuleRoute'
import Trainings from './contractors/trainings/Index'
import TrainingShow from './contractors/trainings/Show'
import ControlCO2 from './controlCO2/Index'
import QualityCertificate from './qualityCertificate/index'
import QualityCertificateShow from './qualityCertificate/show'
import GuidelineDetail from './specifications/guidelines/Detail'
import GuidelinesIndex from './specifications/guidelines/Index'
import PackageSpecificationDetail from './specifications/packageSpecification/Detail'
import PackageSpecification from './specifications/packageSpecification/Index'

/**import FactoringAffiliation */

import RequestFactoringAffiliation from './factoringAffiliation/Create'
import FactoringAffiliationIndex from './factoringAffiliation/Index'
import FactoringAffiliationShow from './factoringAffiliation/Show'
import ImportRequestsIndex from './importRequests/Index'
import ImportReuqestsShow from './importRequests/Show'
import ExportRequestsIndex from './exportRequest/Index'
import ExportRequestsShow from './exportRequest/Show.js'
import LoadAlertIndex from './loadAlert/Index'
import LoadAlertShow from './loadAlert/Show'
import SearchPartNumberIndex from './searchPartNumbers/Index'

import IndirectPriceQuoteWithoutCodeIndex from './indirectPriceQuoteWithoutCode/Index'
import IndirectPriceQuoteWithoutCodeShow from './indirectPriceQuoteWithoutCode/Show'
import IndirectPriceQuoteWithoutCodeShowSupplier from './indirectPriceQuoteWithoutCode/ShowSupplier.js'

/**
 * MainContainer view
 *
 * @class MainContainer
 * @extends {Component}
 */
class MainContainer extends Component {
	/**
	 * Render
	 *
	 * @returns
	 * @memberof MainContainer
	 */
	render() {
		return (
			<Switch>
				<Route exact path='/' component={Home} />
				<Route exact path='/unauthorized' component={Unauthorized} />

				<Route exact path='/acceptedNotices' component={AcceptedNotices} />
				<Route exact path='/ethiccode/questionnaires' component={EthicCodeQuestionnaires} />
				<Route exact path='/updatecontacts' component={UpdateContactSupplier} />

				{/** Import administration component section */}
				<Route exact path='/companies' component={IndexCompanies} />
				<Route exact path='/viewcompany/:token' component={CompanyShow} />
				<Route exact path='/internalusers' component={InternalUserIndex} />
				<Route exact path='/internalusers/:token' component={InternalUserShow} />
				<Route exact path='/internalusers/profile/:token' component={InterUserProfile} />
				<Route exact path='/externalusers' component={ExternalUserIndex} />
				<Route exact path='/externalusers/:token' component={ExternalUserShow} />
				<Route exact path='/externalusers/profile/:token' component={ExternalUserProfile} />
				<Route exact path='/suppliers' component={SupplierIndex} />
				<Route exact path='/suppliers/:token' component={SupplierShow} />
				<Route exact path='/suppliers/ratings/:token' component={SupplierRatings} />
				<Route exact path='/suppliers/ratings/:token/indirects' component={SupplierRatingsIndirects} />
				<Route exact path='/suppliers/supplier/profile' component={GetProfileSupplier} />
				<Route exact path='/suppliers/invoices/:token' component={InvoicesQuery} />
				<Route exact path='/suppliers/inventorymaquila/:token' component={InventoryMaquilas} />
				<Route exact path='/suppliers/purchaseOrders/:token' component={PurchaseOrdersQuery} />
				<Route exact path='/suppliers/inventories/:token' component={InventoriesQuery} />
				<Route exact path='/suppliers/consignments/:token' component={ConsignmentQuery} />
				<Route exact path='/suppliers/stocks/:token' component={StocksQuery} />
				<Route exact path='/warehouseworkers' component={WarehouseStaffIndex} />
				<Route exact path='/officialnotices' component={OfficialNoticeIndex} />
				<Route exact path='/officialnotices/create' component={OfficialNoticeCreate} />
				<Route exact path='/officialnotices/:token' component={OfficialNoticeShow} />
				<Route exact path='/securityCourses' component={SecurityCoursesIndex} />
				<Route exact path='/certifications' component={Certifications} />
				<Route exact path='/certifications/:code' component={Certifications} />
				<Route exact path='/all/certifications' component={AllCertifications} />
				<Route exact path='/securityCourses/:token' component={SecurityCoursesDetail} />
				<Route exact path='/manualsportal' component={ManualsPortal} />
				<Route exact path='/transportTenderShow' component={TransportTenderIndex} />
				<Route exact path='/transportTenderShow/:token' component={TransportTenderdetail} />
				<Route exact path='/factoringaffiliation' component={FactoringAffiliationIndex} />
				<Route exact path='/factoringaffiliation/:token' component={FactoringAffiliationShow} />
				<Route exact path='/factoringaffiliation/:token/show/:id' component={RequestFactoringAffiliation} />
				{/* <Route exact path='/transportTenderConfiguration' component={ConfigurationIndex} /> */}
				<Route exact path='/transportTender/catalog/transportZones' component={TransportZone} />
				<Route exact path='/transportTender/catalog/typesTransport' component={TransportType} />
				<Route exact path='/transportTender/catalog/wineCellar' component={WineCellar} />
				<Route exact path='/transportTender/catalog/carrierTypes' component={TypeShipment} />
				<Route exact path='/transportTender/catalog/availableSchedules' component={AvailableSchedules} />
				<Route exact path='/transportTender/catalog/requirementsCarriers' component={RequirementsCarriers} />
				<Route exact path='/transportTender/catalog/maneuvers' component={ManeuverCatalogIndex} />

				<Route exact path='/transportTender/catalog/rates' component={Rates} />
				<Route exact path='/transportTender/catalog/clients' component={Clients} />
				<Route exact path='/ethiccode' component={EthicCodeIndex} />
				<Route exact path='/ethiccode/create' component={EthicCodeCreate} />
				<Route exact path='/ethiccode/:token' component={EthicCodeShow} />
				<Route exact path='/phoneDirectory' component={PhoneDirectoryIndex} />
				<Route exact path='/phoneDirectory/:token/:activeTab' component={PhoneDirectoryIndex} />
				<Route exact path='/contactsupplier' component={ContactSupplierIndex} />
				<Route exact path='/contactsupplier/create' component={ContactSupplierCreate} />
				<Route exact path='/contactsupplier/:token' component={ContactSupplierShow} />
				<Route exact path='/expedientsupplier' component={ExpedientSupplierIndex} />
				<Route exact path='/expedientsupplier/create' component={ExpedientSupplierCreate} />
				<Route exact path='/expedientsupplier/:token' component={ExpedientSupplierShow} />
				<Route exact path='/expedientsbysupplier' component={ExpedientsBySupplierIndex} />
				<Route exact path='/expedientsbysupplier/:token' component={ShowExpedientBySupplier} />
				<Route exact path='/contracts' component={SupplierContracts} />
				<Route exact path='/contracts/create' component={SupplierContractsCreate} />
				<Route exact path='/contracts/request' component={SupplierContractsRequest} />
				<Route exact path='/contracts/:token' component={SupplierContractAhow} />
				<Route exact path='/documents' component={DocumentsEnviorements} />
				<Route exact path='/documents/create' component={DocumentsEnviorementCreate} />
				<Route exact path='/documents/:token' component={DocumentsEnviorementAhow} />

				<Route exact path='/prospects' component={ProspectsIndex} />
				<Route exact path='/prospects/create' component={ProspectCreate} />
				<Route exact path='/prospects/:token' component={ProspectShow} />

				{/** Import development component section */}
				<Route exact path='/indirectPriceQuoteWithoutCode' component={IndirectPriceQuoteWithoutCodeIndex} />
				<Route exact path='/indirectPriceQuoteWithoutCode/:token' component={IndirectPriceQuoteWithoutCodeShow} />
				<Route exact path='/indirectPriceQuoteWithoutCode/:token/show' component={IndirectPriceQuoteWithoutCodeShowSupplier} />
				
				<Route exact path='/memos' component={MemosIndex} />
				<Route exact path='/memos/create' component={MemoCreate} />
				<Route exact path='/memos/:token' component={MemoShow} />
				<Route exact path='/noticechanges' component={NoticeChangesIndex} />
				<Route exact path='/noticechanges/create' component={NoticeChangeCreate} />
				<Route exact path='/noticechanges/:token' component={NoticeChangeShow} />
				<Route exact path='/noticechanges/notification/:token' component={ViewNotification} />
				<Route exact path='/noticechanges/cancel/:token' component={CancelNoticeContainer} />
				<Route exact path='/materialdelivery' component={MaterialDeliveryIndex} />
				<Route exact path='/materialdelivery/create' component={MaterialDeliveryCreate} />
				<Route exact path='/materialdelivery/:token' component={MaterialDeliveryShow} />
				<Route exact path='/quotes' component={QuotesIndex} />
				<Route exact path='/quotes/:token' component={QuoteShow} />
				<Route exact path='/quotes/reminder/:token' component={ReminderContainer} />
				<Route exact path='/quotes/cancel/:token' component={CancelContainerQuote} />
				<Route exact path='/samplerequests' component={SampleRequestsIndex} />
				<Route exact path='/samplerequests/:token' component={SampleRequestShow} />
				<Route exact path='/samplerequests/cancel/:token' component={CancelContainerSample} />
				<Route exact path='/samplerequests/reminder/:token' component={ReminderContainerToSupplier} />
				<Route exact path='/alternativeprovedure' component={AlternativeProvedureiaIndex} />
				<Route exact path='/alternativeprovedure/create' component={AlternativeProvedureiaCreate} />
				<Route exact path='/alternativeprovedure/:token' component={AlternativeProvedureShow} />
				<Route exact path='/priceChangeRequest' component={PriceChangeRequestIndex} />
				<Route exact path='/priceChangeRequest/create' component={PriceChangeRequestCreate} />
				<Route exact path='/priceChangeRequest/:token' component={RequestPriceChangeDetail} />
				<Route exact path='/correctiveactions' component={SacsIndex} />
				<Route exact path='/correctiveactions/create' component={SacsCreate} />
				<Route exact path='/correctiveactions/cancel/:token' component={CancelContainer} />
				<Route exact path='/correctiveactions/:token' component={SacsShow} />
				<Route exact path='/partnumbers' component={PartNumberHistory} />
				<Route exact path='/qualifications' component={Qualifications} />
				<Route exact path='/ratings/import' component={ImportRating} />
				<Route exact path='/ratings/generate' component={GenerateRatings} />
				<Route exact path='/ratings/generatingPTM' component={GenerateRatingPtm} />
				<Route exact path='/ratings/generatingFERSA' component={GenerateRatingFERSA} />
				<Route exact path='/planning' component={PlanningIndex} />
				<Route exact path='/planning/relist/:supplierCode' component={PlanningRelist} />
				<Route exact path='/planning/deliveryOrderFersa/create' component={DeliveryOrderCreateFersa} />
				<Route exact path='/planning/deliveryOrderFersa/:token' component={ShowDeliveryOrden} />
				<Route exact path='/deliveryOrderFersa/registerarrival' component={DeliveryOrderRegisterFolioFersa} />
				<Route exact path='/releasecalendarFersa' component={ReleasecalendarFersa} />
				<Route exact path='/releasecalendarFersa/create' component={ReleaseCalendarCreateFersa} />
				<Route exact path='/releasecalendarFersa/:token' component={ReleaseCalendarShowFersa} />
				<Route exact path='/sparePartPriceChange' component={SparePartPriceChangeIndex} />
				<Route exact path='/sparePartPriceChange/:activeTab' component={SparePartPriceChangeIndex} />
				<Route exact path='/sparePartPriceChange/show/:token' component={SparePartPricechangeShow} />

				{/** Import quality component section */}
				<Route exact path='/rawmaterialnonconformity' component={RawMaterialNonConformityIndex} />
				<Route exact path='/rawmaterialnonconformity/create' component={RawMaterialNonConformityCreate} />
				<Route exact path='/rawmaterialnonconformity/:token' component={RawMaterialNonConformityShow} />
				<Route exact path='/rawmaterialnonconformity/edit/:token' component={RawMaterialNonConformityEdit} />
				<Route
					exact
					path='/rawmaterialnonconformity/sendNotification/:token'
					component={RawMaterialSendNotification}
				/>
				<Route
					exact
					path='/rawmaterialnonconformity/registerDeviation/:token'
					component={RawMaterialRegisterDeviation}
				/>
				<Route
					exact
					path='/rawmaterialnonconformity/createdevolution/:token'
					component={RawMaterialCreateDevolution}
				/>
				<Route exact path='/rawmaterialnonconformity/createrework/:token' component={RawMaterialCreateRework} />
				<Route
					exact
					path='/rawmaterialnonconformity/createfromDelivery/:token/:itemToken'
					component={CreateRawMaterialFromDelivery}
				/>
				<Route exact path='/rawmaterialnonconformity/cancel/:token' component={CancelContainerRawMaterial} />
				<Route exact path='/qualityfailurereport' component={QualityFailureReportIndex} />
				<Route exact path='/qualityfailurereport/create' component={QualityFailureReportCreate} />
				<Route exact path='/qualityfailurereport/edit/:token' component={QualityFailureReportEdit} />
				<Route exact path='/qualityfailurereport/:token' component={QualityFailureReportShow} />
				<Route
					exact
					path='/qualityfailurereport/sendNotification/:token'
					component={QualityFailureSendNotification}
				/>
				<Route
					exact
					path='/qualityfailurereport/createdevolution/:token'
					component={QualityFailureReportDevolution}
				/>
				<Route exact path='/qualityfailurereport/createrework/:token' component={QualityFailureReportRework} />
				<Route
					exact
					path='/qualityfailurereport/registerDeviation/:token'
					component={QualityFailureReportDeviation}
				/>
				<Route exact path='/qualityfailurereport/cancel/:token' component={CancelContainerQualityFailure} />
				<Route exact path='/managequalityworkers' component={UsersQualityIndex} />
				<Route exact path='/nonconformities' component={NonConformityIndex} />
				<Route exact path='/reworks' component={ReworksIndex} />
				<Route exact path='/reworks/create' component={ReworksCreate} />
				<Route exact path='/reworks/:token' component={ReworksShow} />
				<Route exact path='/reworks/reworkreport/:token' component={ReworksReworkReport} />
				<Route exact path='/reworks/createdevolution/:token' component={ReworksDevolution} />
				<Route exact path='/sacsquality' component={SacsQualityIndex} />
				<Route exact path='/sacsquality/create' component={SacsQualityCreate} />
				<Route exact path='/sacsquality/:token' component={SacsQualityShow} />
				<Route exact path='/sacsquality/cancel/:token' component={CancelContainerSacQuality} />
				<Route exact path='/sacsquality/sendNotification/:token' component={SacsQualitySendNotification} />
				<Route
					exact
					path='/sacsquality/createActionsBySupplier/:token'
					component={SacsQualityActionsSupplier}
				/>
				<Route exact path='/devolutions' component={DevolutionsIndex} />
				<Route exact path='/devolutions/create' component={DevolutionsCreate} />
				<Route exact path='/devolutions/:token' component={DevolutionShow} />
				<Route exact path='/devolutions/cancel/:token' component={CancelContainerDevolution} />
				<Route exact path='/qualityaudit' component={QualityAuditIndex} />
				<Route exact path='/qualityaudit/:token' component={QualityAuditShow} />
				<Route exact path='/warrantyReportsPtm' component={WarrantyReportIndex} />
				<Route exact path='/warrantyReportsPtm/create' component={WarrantyReportCreate} />
				<Route exact path='/warrantyReportsPtm/:token' component={WarrantyReportShow} />
				<Route exact path='/warrantyReportsPtm/cancel/:token' component={WarrantyReportCancel} />

				{/** Import shipping orders component section  */}
				<Route exact path='/shippingorders' component={ShippingOrdersIndex} />
				<Route exact path='/shippingorders/:token' component={ShippingOrdersShow} />

				{/** Import freight component section  */}
				<Route exact path='/freight' component={FreightIndex} />
				<Route exact path='/freight/:token' component={FreightShow} />

				{/** Import warehouse raw material component section */}
				<Route exact path='/warehouseRawMaterials' component={WarehouseRawMaterialIndex} />
				<Route exact path='/warehouseRawMaterials/create' component={WarehouseRawMaterialCreate} />
				<Route exact path='/warehouseRawMaterials/:token' component={WarehouseRawMaterialShow} />

				{/**Import guarantee component section */}
				<Route exact path='/customerguarantee' component={CustomerGuaranteeIndex} />
				<Route exact path='/failureguarantee' component={FailureGuaranteeIdex} />
				<Route exact path='/guaranteereport' component={GuaranteeReport} />
				<Route exact path='/guaranteereport/create' component={GuaranteeReportCreate} />
				<Route exact path='/guaranteereport/:token' component={GuaranteeReportShow} />
				<Route exact path='/guaranteereport/sendnotification/:token' component={GuaranteeSendNotification} />
				<Route exact path='/guaranteereport/edit/:token' component={GuaranteeReportEdit} />
				<Route exact path='/guaranteereport/cancel/:token' component={GuaranteeReportCancel} />
				<Route exact path='/guaranteereport/notecredit/:token' component={AddNoteCredit} />

				{/** Routes to contractors modules */}
				<Route exact path='/accesscontrol/upload' component={AccessControlRegisters} />
				<Route exact path='/employees' component={EmployeesIndex} />
				<Route exact path='/employees/cards' component={EmployeeCardsShow} />
				<Route exact path='/employees/:token' component={EmployeeShow} />
				<Route exact path='/socialinsurancepayments/' component={SocialInsurancePaymentsIndex} />
				<Route exact path='/socialinsurancepayments/:token' component={SocialInsurancePaymentShow} />
				<Route exact path='/servicesprojects/' component={ServiceProjectsIndex} />
				<Route exact path='/servicesprojects/supplier/:code' component={ServiceProjectsIndexSupplier} />
				<Route exact path='/servicesprojects/:token' component={ServiceProjectShow} />
				<Route exact path='/medicalchecks/' component={MedicalCheckIndex} />
				<Route exact path='/trainings/' component={Trainings} />
				<Route exact path='/trainings/:token' component={TrainingShow} />
				<Route exact path='/medicalchecks/:token' component={MedicalCheckShow} />

				{/** Import planning component section  */}
				<Route exact path='/planning/releaseplan/:token' component={ReleasePlan} />
				<Route exact path='/releaseplan/upload' component={ReleasePlanUpload} />
				<Route exact path='/releasecalendar' component={ReleaseCalendarLock} />
				<Route exact path='/releasecalendar/create' component={ReleaseCalendarCreate} />
				<Route exact path='/releasecalendar/:token' component={ReleaseCalendarShow} />
				<Route exact path='/planning/deliveryorder' component={DeliveryOrderIndex} />
				<Route exact path='/planning/deliveryorder/create' component={DeliveryOrderCreate} />
				<Route exact path='/planning/deliveryorder/:token' component={DeliveryOrderShow} />
				<Route exact path='/deliveryorder/registerarrival' component={DeliveryOrderRegisterFolio} />
				<Route exact path='/destinations' component={TranposrtDestinations} />

				{/** Sustainaibility Questionnaires section  */}
				<Route exact path='/sustainability' component={SustainabilityIndex} />
				<Route exact path='/sustainability/create' component={SustainabilityCreate} />
				<Route exact path='/sustainability/:token' component={SustainabilityShow} />
				<Route exact path='/sustainability/cancel/:token' component={CancelContainerSustainability} />
				<Route exact path='/questionnaire' component={QuestionnaireIndex} />
				<Route exact path='/questionnaire/create' component={QuestionnaireCreate} />
				<Route exact path='/questionnaire/calendar' component={QuestionnaireCalendarIndex} />
				<Route exact path='/questionnaire/:token' component={QuestionnaireShow} />
				<Route exact path='/templates' component={TemplatesIndex} />
				<Route exact path='/templates/:token' component={TemplatesShow} />

				<Route exact path='/sustainabilityv2' component={SustainabilityIndexList} />
				<Route exact path='/sustainabilityv2/template' component={SustainabilityTemplate} />
				<Route exact path='/sustainabilityv2/calendar' component={SustainabilityCalendar} />
				<Route exact path='/sustainabilityv2/:token' component={SustainabilityShowV2} />

				<Route exact path='/qualityCertificate' component={QualityCertificate} />
				<Route exact path='/qualityCertificate/:token' component={QualityCertificateShow} />
				<Route exact path='/specifications/guidelines' component={GuidelinesIndex} />
				<Route exact path='/specifications/guidelines/:token' component={GuidelineDetail} />
				<Route exact path='/specifications/package' component={PackageSpecification} />
				<Route exact path='/specifications/package/:token' component={PackageSpecificationDetail} />

				{/** Accredited workshops section  */}
				<ModuleRoute
					exact
					path='/accreditedWorkshops'
					component={AccreditedWorkshopsIndex}
					moduleName={moduleNames.ACCREDITEDWORKSHOPSPAYMENTS}
				/>
				<ModuleRoute
					exact
					path='/accreditedWorkshops/:activeTab'
					moduleName={moduleNames.ACCREDITEDWORKSHOPSPAYMENTS}
					component={AccreditedWorkshopsIndex}
				/>
				<ModuleRoute
					exact
					path='/accreditedWorkshops/paymentRequest/:token'
					component={PaymentRequestView}
					moduleName={moduleNames.ACCREDITEDWORKSHOPSPAYMENTS}
				/>

				{/** biddings section  */}
				<Route exact path='/biddings' component={BiddingsIndex} />
				<Route exact path='/biddings/base/create/:token' component={BiddingsBaseCreate} />
				<Route exact path='/biddings/:token' component={BiddingsShow} />
				{/** Indirect Qualifications section*/}
				<Route exact path='/indirectqualifications' component={IndirectQualificationsIndex} />
				<Route exact path='/indirectqualifications/:token' component={IndirectQualificationsShow} />
				<Route
					exact
					path='/indirectqualifications/actionsPlant/:token/:supplierId'
					component={IndirectQualificationsShowActionPlant}
				/>
				{/** maquila Orders section  */}
				<Route exact path='/maquilaOrders' component={MaquilaOrdersIndex} />
				<Route exact path='/maquilaOrders/:token' component={MaquilaShow} />

				{/** biddings section  */}
				<Route exact path='/billings' component={BillingsIndex} />
				<Route exact path='/billing/:token' component={BillingsShow} />

				{/** warehouseWindows section  */}
				<Route exact path='/warehouseWindows' component={WarehouseWindowsIndex} />
				<Route exact path='/warehouseWindows/calendar' component={WarehouseWindowsCalendarIndex} />

				<Route exact path='/controlCO2' component={ControlCO2} />
				<Route exact path='/controlCO2/:activeTab' component={ControlCO2} />

				{/** consignmentNote section  CONSIGNEMENTNOTE */}
				<Route exact path='/consignmentNote' component={ConsignmentNoteIndex} />
				<Route exact path='/ferrys' component={IndexFerryCuponManager} />
				<Route exact path='/consignmentNote/:token' component={ConsignmentNoteShow} />

				<Route exact path='/deliveryQualifications' component={DeliveryQualificationsIndex} />
				<Route exact path='/deliveryQualifications/:token' component={DeliveryQualificationsShow} />
				<Route exact path='/importRequests' component={ImportRequestsIndex} />
				<Route exact path='/importRequests/:token' component={ImportReuqestsShow} />
				<Route exact path='/exportRequests' component={ExportRequestsIndex} />
				<Route exact path='/exportRequests/:token' component={ExportRequestsShow} />
				<Route exact path='/searchPartNumbers' component={SearchPartNumberIndex} />
				<Route exact path='/loadAlerts' component={LoadAlertIndex} />
				<Route exact path='/loadAlerts/:token' component={LoadAlertShow} />
				
				<Route exact path='/survey' component={SurveysIndex} />
				<Route exact path='/survey/create' component={SurveysCreate} />
				<Route exact path='/survey/showSupplier' component={ShowSupplier} />
				<Route exact path='/survey/:id' component={SurveysShow} />

				
				<Route exact path='/surveyTemplate' component={SurveyTemplatesIndex} />
				<Route exact path='/surveyTemplate/:id' component={SurveyTemplatesShow} />

				
				

			</Switch>
		)
	}
}

export default MainContainer
