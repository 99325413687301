import React from 'react'
// Importaciones de Material-UI
import { Typography, Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const EmptySections = (props) => {
	const { classes, surveyTemplate, onOpenCloseSurverSectionConfigPanel, canBeEditData } = props

	return (
		<>
			{surveyTemplate && surveyTemplate.Sections.length === 0 && (
				<div className={classes.emptyStateContainer}>
					<Typography variant='h6' color='textSecondary' align='center'>
						Aún no hay secciones disponibles
					</Typography>
					<Typography variant='body2' color='textSecondary' align='center'>
						Comienza agregando la primera sección para tu encuesta.
					</Typography>
					{canBeEditData && (
						<Button
							variant='contained'
							color='primary'
							size='medium'
							className={classes.addSectionButton}
							onClick={onOpenCloseSurverSectionConfigPanel}
						>
							Agregar Sección
						</Button>
					)}
				</div>
			)}
		</>
	)
}

const styles = (theme) => ({
	emptyStateContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '32px',
		backgroundColor: '#fff',
		borderRadius: '8px',
		boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
		marginTop: '16px',
	},
	addSectionButton: {
		marginTop: '16px',
		textTransform: 'none',
		fontSize: '1rem',
	},
})

export default withStyles(styles)(EmptySections)
