import React from 'react'
import {
	Typography,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Avatar,
	ExpansionPanel,
	ExpansionPanelSummary,
	ExpansionPanelDetails,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { CalendarToday, Business } from '@material-ui/icons'
// Importaciones de Material-UI
import { withStyles } from '@material-ui/core/styles'
// import PercentIcon from '@material-ui/icons/Percent';
// import SupplierCard from './SupplierCard'
// import SurveyCard from './SurveyCard'
const SupplierInSurvey = (props) => {
	const { survey } = props

	return (
		<>
			{/* Información de los proveedores */}
			<Typography variant='h6' color='textPrimary' style={{ fontWeight: 'bold', marginBottom: 16 }}>
				Proveedores
			</Typography>
			{survey.SupplierSurveyResponse.map((response) => (
				<ExpansionPanel key={response.Id}>
					<ExpansionPanelSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls={`panel-${response.Id}-content`}
						id={`panel-${response.Id}-header`}
					>
						<Typography>
							{response.SurveySupplier.Code + ' | ' + response.SurveySupplier.Name ||
								'Proveedor Desconocido'}
						</Typography>
					</ExpansionPanelSummary>
					<ExpansionPanelDetails>
						<List>
							<ListItem>
								<ListItemAvatar>
									<Avatar>
										<Business />
									</Avatar>
								</ListItemAvatar>
								<ListItemText
									primary='Código'
									secondary={response.SurveySupplier.Code || 'No disponible'}
								/>
							</ListItem>

							<ListItem>
								<ListItemAvatar>
									<Avatar>
										<CalendarToday />
									</Avatar>
								</ListItemAvatar>
								<ListItemText
									primary='Fecha de Respuesta'
									secondary={response.ResponseDate || 'No disponible'}
								/>
							</ListItem>

							{/* Respuestas */}
							{response.Answers.length > 0 ? (
								response.Answers.map((answer, index) => (
									<ListItem key={index}>
										<ListItemText
											primary={`Respuesta ${index + 1}`}
											secondary={answer || 'No disponible'}
										/>
									</ListItem>
								))
							) : (
								<ListItem>
									<Typography variant='body2' color='textSecondary' style={{ marginLeft: 32 }}>
										No hay respuestas disponibles.
									</Typography>
								</ListItem>
							)}
						</List>
					</ExpansionPanelDetails>
				</ExpansionPanel>
			))}
		</>
	)
}

const styles = (theme) => ({})

export default withStyles(styles)(SupplierInSurvey)
