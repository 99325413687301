import React from 'react'
import moment from 'moment'
/** Translations imports section */
import { Translate } from 'react-localize-redux'

/** Material-UI imports section */
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Avatar from '@material-ui/core/Avatar'
import Grid from '@material-ui/core/Grid'
import EthicCodeIcon from '@material-ui/icons/ListAlt'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Toolbar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import DateTimePicker from '../../common/DateTimePicker/DatePicker'
import Evidence from '../../common/Evidence'
import { withStyles } from '@material-ui/core/styles'
// import PercentIcon from '@material-ui/icons/Percent';
import { mappedSurveyTemplatesOptions } from '../../../store/helpers/SelectOptions'
import ReactSelect from 'react-select'
const SurveyCard = (props) => {
	//bool props
	const { classes, isSaving } = props
	//functions
	const { onRemoveFiles, onUploadFiles, canBeSaved, onCreateSurvey, onChangeTemplate, onUpdateProperty } = props
	//objects
	const { surveyData } = props
	//arrays
	const { surveyTemplates } = props
	let surveyTemplatesOptions = mappedSurveyTemplatesOptions(surveyTemplates)
	return (
		<Card className={classes.card} style={{ height: '79vh' }}>
			<CardHeader
				avatar={
					<Avatar className={classes.userAvatar}>
						<EthicCodeIcon />
					</Avatar>
				}
				title={<Translate id='surveys.addSurvey' />}
			/>
			<CardContent style={{ height: '60vh', overflowY: 'auto' }}>
				<Grid container spacing={24}>
					<Grid item xs={12}>
						<TextField
							fullWidth
							id='name'
							label={<Translate id='surveys.name' />}
							className={classes.textAreaField}
							value={surveyData ? surveyData.name : ''}
							margin='dense'
							variant='outlined'
							onChange={(event) => {
								onUpdateProperty('name', event.target.value)
							}}
						/>
					</Grid>
					<Grid item xs={12}>
						<Typography variant='body2'>Selecciona la plantilla</Typography>
						<ReactSelect
							className={classes.documentTypeSelect}
							options={surveyTemplatesOptions}
							onChange={onChangeTemplate}
							styles={{
								// menu: (base) => ({ ...base, zIndex: 10 })
								menu: (base) => ({ ...base, zIndex: 10, maxHeight: 200 }),
								menuList: (base) => ({ ...base, maxHeight: 200, paddingTop: 0 }),
								menuPortal: (base) => ({ ...base, zIndex: 9999 }), /// THIS IS TO SHOW MENU OVER MODAL
							}}
							menuPosition='fixed' //THIS IS REQUIRED IN MODAL!!
							menuPlacement={'auto'}
							// isLoading={loadingWorkshops}
						/>
					</Grid>
					<Grid item xs={12}>
						<FormControlLabel
							control={
								<Checkbox
									onChange={(event) => {
										onUpdateProperty('acceptedBySupplier', event.target.checked)
									}}
									color='primary'
									value='status'
								/>
							}
							label={<Translate id='surveys.setToAcceptBySupplier' />}
						/>
					</Grid>
					<Grid item xs={6}>
						<Typography>{<Translate id='officialNotices.activationDate' />}</Typography>
						<DateTimePicker
							onChangeRange={(deliveryDate) => {
								onUpdateProperty('activationDate', deliveryDate)
							}}
							minDate={moment()}
							startDate={surveyData.activationDate ? surveyData.activationDate : null}
						/>
					</Grid>
					<Grid item xs={6}>
						<Typography>{<Translate id='officialNotices.expirationDate' />}</Typography>
						<DateTimePicker
							onChangeRange={(deliveryDate) => {
								onUpdateProperty('expirationDate', deliveryDate)
							}}
							minDate={moment()}
							startDate={surveyData.expirationDate ? surveyData.expirationDate : null}

							// startDate={this.state.delivery_date}
						/>
					</Grid>
					<Grid item xs={12}>
						<Typography variant='subtitle2' color={'textSecondary'}>
							<Translate id={'surveys.messageToAddFile'} />
						</Typography>
						<Evidence
							text={'Arrastra y suelta un archivo o haz clic'}
							baseApi={process.env.REACT_APP_SIP_URL_ADMINISTRATION}
							removeFiles={onRemoveFiles}
							uploadFiles={onUploadFiles}
							files={surveyData.attachments}
							disabledLink
						/>
					</Grid>
				</Grid>
			</CardContent>

			<CardActions className={classes.actions}>
				<Grid item xs={12}>
					<Toolbar>
						<Button
							variant='outlined'
							color='secondary'
							className={classes.button}
							onClick={() => onCreateSurvey(surveyData)}
							disabled={!canBeSaved() || isSaving}
						>
							<Translate id={isSaving ? 'common.saving' : 'common.save'} />
						</Button>
						<Button
							variant='outlined'
							color='primary'
							className={classes.button}
							data-cy='btn-close'
							// onClick={() => {
							// 	history.push(`/surveyData`)
							// }}
						>
							<Translate id='common.cancel' />
						</Button>
					</Toolbar>
				</Grid>
			</CardActions>
		</Card>
	)
}

const styles = (theme) => ({
	userAvatar: {
		backgroundColor: theme.palette.secondary.main,
	},
	textField: {
		marginLeft: theme.spacing.unit,
		marginRight: theme.spacing.unit,
		width: 240,
	},
	toolBar: {
		backgroundColor: 'red',
	},
	button: {
		margin: theme.spacing.unit,
	},
	errorMessage: {
		color: theme.palette.common.white,
	},
	formatCheck: {
		display: 'flex',
		marginLeft: theme.spacing.unit,
		width: 460,
	},
})

export default withStyles(styles)(SurveyCard)
