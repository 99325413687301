///Import react section
import React, { useEffect, useState } from 'react'
import { Translate } from 'react-localize-redux'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, withRouter } from 'react-router-dom'

/** Material-UI imports section */
import { Avatar, Card, CardContent, CardHeader, Chip, Typography } from '@material-ui/core'
import Fab from '@material-ui/core/Fab'
import { withStyles } from '@material-ui/core/styles'
import { Notifications } from '@material-ui/icons'
import AddIcon from '@material-ui/icons/Add'

import * as roles from '../../store/helpers/RolesHelper'
import { IsCurrentActiveDirectoryUser } from '../../store/helpers/SessionHelper'

//import components section
import DataTable from '../common/DataTable'
import SearchInput from '../common/SearchInput/SearchInput'
import withauthenticatedLayout from '../layouts/withauthenticatedLayout'
import AddLoadAlertPanel from './componentes/index/AddLoadAlertPanel'

//import material ui section
import { toSafeObject } from '../../store/helpers/ListHelper'
import {
	changeLoadAlertsPagination,
	changeLoadAlertsQuery,
	loadAlertsFromBackEnd,
} from '../../store/loadAlerts/LoadAlertAction'
import { onGetBackgroundColorChip, onGetStatusDescriptionChip } from '../../store/helpers/LoadAlertHelper'

const Index = (props) => {
	const dispatch = useDispatch()
	let history = useHistory()
	const userProfile = useSelector((state) => state.oidc.user)
	let userRolesByCompany = useSelector((state) => state.profile.get('userRolesByCompany').toJS())
	const loadAlerts = useSelector((state) =>
		state.loadAlert.get('loadAlerts') ? state.loadAlert.get('loadAlerts').toJS() : []
	)
	const isSaving = useSelector((state) => (state.loadAlert.get('isSaving') ? state.loadAlert.get('isSaving') : []))
	const page = useSelector((state) => state.loadAlert.get('page'))
	const shippingOrdersCount = useSelector((state) => state.loadAlert.get('loadCount'))
	const rowsPerPage = useSelector((state) => state.loadAlert.get('rowsPerPage'))

	const sortCriteria = useSelector((state) => state.loadAlert.get('sortCriteria').toJS())
	const query = useSelector((state) => state.loadAlert.get('searchQuery'))
	const administratorSupplier = useSelector((state) =>
		state.externalUsers.get('administratorSupplier')
			? toSafeObject(state.externalUsers.get('administratorSupplier'))
			: null
	)

	let isInternalUser = userProfile ? userProfile.profile.user_type === 'ActiveDirectoryUser' : ''

	//Use state section
	const [isOpenPanelCreateAlert, setIsOpenPanelCreateAlert] = useState(false)

	const onCreateLoadAlert = () => {
		setIsOpenPanelCreateAlert(!isOpenPanelCreateAlert)
	}

	const getShippingOrdersCounterMessage = () => {
		if (loadAlerts) {
			let totalshippingOrders = loadAlerts.length
			return (
				<div>
					{<Translate id='common.showing' />}&nbsp;
					<span>{totalshippingOrders}</span>&nbsp;
					{<Translate id='common.of' />}&nbsp;
					<span>{shippingOrdersCount}</span>&nbsp;
					{<Translate id='common.records' />}
				</div>
			)
		}
	}
	const onChangePage = (page, rowsPerPage) => {
		dispatch(changeLoadAlertsPagination(page, rowsPerPage))
	}
	const onChangeSearchValue = (value) => {
		dispatch(changeLoadAlertsQuery(value))
	}
	useEffect(() => {
		dispatch(loadAlertsFromBackEnd(query, sortCriteria.by, sortCriteria.ascending, page, rowsPerPage))
	}, [dispatch, query, sortCriteria.by, sortCriteria.ascending, page, rowsPerPage])

	const onRedirectToDetail = (id) => props.history.push(`/loadAlerts/${id}`)

	const onRendercustomCell = (dataSource, item) => {
		switch (dataSource) {
			case 'statusDescription': {
				return (
					<Chip
						label={<Translate id={onGetStatusDescriptionChip(item.status)} />}
						style={{
							borderRadius: '5px',
							width: '100%',
							height: '25px',
							backgroundColor: onGetBackgroundColorChip(item.status),
						}}
						color='inherit'
					/>
				)
			}
			default:
				return ''
		}
	}
	return (
		<Card style={{ marginTop: '20px' }}>
			<CardHeader
				avatar={
					<Avatar>
						<Notifications />
					</Avatar>
				}
				action={
					<div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
						<SearchInput onChangeSearchValue={onChangeSearchValue} value={query} />
						{((roles.canCreateLoadAlert(userRolesByCompany) && !isInternalUser) || isInternalUser) && (
							<Fab size='small' color='primary' onClick={onCreateLoadAlert}>
								<AddIcon />
							</Fab>
						)}
					</div>
				}
				title={
					<Typography variant='button'>
						<Translate id='menu.loadAlerts' />{' '}
					</Typography>
				}
				subheader={getShippingOrdersCounterMessage()}
			/>
			<CardContent>
				{
					<DataTable
						data={loadAlerts}
						configuration={TableConfiguration(IsCurrentActiveDirectoryUser(userProfile))}
						// sortBy={sortCriteria.by}
						sortAscending={sortCriteria.ascending}
						// onChangeSortCriteria={onChangeSortCriteria}
						onRowClick={(shippingOrder) => {
							history.push(`/loadAlerts/${shippingOrder.token}`)
						}}
						showFilters
						isIndex
						totalRows={shippingOrdersCount}
						onRenderCellItem={onRendercustomCell}
						page={page}
						rowsPerPage={rowsPerPage}
						onChangePage={(newPage, newRowsPerPage) => {
							onChangePage(newPage, newRowsPerPage)
						}}
					/>
				}
			</CardContent>

			{isOpenPanelCreateAlert && (
				<AddLoadAlertPanel
					//Bool props section
					isInternalUser={isInternalUser}
					isOpenPanelCreateAlert={isOpenPanelCreateAlert}
					isSaving={isSaving}
					//Function props section
					onRedirectToDetail={onRedirectToDetail}
					onCloseButtonClick={() => setIsOpenPanelCreateAlert(!isOpenPanelCreateAlert)}
					//object props section
					administratorSupplier={administratorSupplier}
				/>
			)}
		</Card>
	)
}
function TableConfiguration(isUserInternal) {
	let columns = [
		{
			header: '',
			dataSource: 'status_indicator',
		},
		{
			header: <Translate id='common.status' />,
			dataSource: 'statusDescription',
			isSortable: true,
		},
		{
			header: <Translate id='common.creationDate' />,
			dataSource: 'creationDate',
		},
		{
			header: <Translate id='common.folio' />,
			dataSource: 'sitFolio',
			isSortable: true,
		},
		{
			header: 'Proveedor de carga',
			dataSource: 'supplierCode',
			isSortable: true,
		},
		{
			header: 'Nombre del proveedor de carga',
			dataSource: 'supplierName',
			isSortable: true,
		},
		{
			header: 'Orden de compra',
			dataSource: 'shippingOrder',
			isSortable: true,
		},
		{
			header: 'Número de referencia',
			dataSource: 'referenceNumber',
			isSortable: true,
		},
	]

	return { columns }
}
const styles = (theme) => ({})

export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme: true })(Index)))
