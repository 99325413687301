/**Import react section */
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import propTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
/**Import MaterialUi section */
import {
	Grid,
	Avatar,
	Button,
	Typography,
	TextField,
	FormControlLabel,
	Switch,
	InputAdornment,
} from '@material-ui/core'
import ViewCarouselIcon from '@material-ui/icons/ViewCarousel'
/**Import components section */
import LateralPanel from '../../../common/LateralPanelComponents/LateralPanel'
import PanelHeader from '../../../common/LateralPanelComponents/PanelHeader'
/**Import store section */
import { IsNullOrEmpty } from '../../../../store/helpers/StringHelper'
import {
	addQuestionOptionFromBackEnd,
	getSurveyTemplateByIdFromBackEnd,
} from '../../../../store/surveyTemplates/SurveyTemplatesActions'

import { SurveyQuestioType } from '../../../../store/helpers/AppConstants'

const AddOptionPanel = (props) => {
	//bool props section
	const { isSaving } = props
	//objects props sections
	const { item, surveyTemplate } = props
	const dispatch = useDispatch()

	const CHARACTER_LIMIT_NAME = 100

	/**props section */
	const { onCloseButtonClick, classes, setToasterMessageConfig } = props

	const [surveyTemplateModel, setSurveyTemplateModel] = useState({
		title: '',
		type: SurveyQuestioType.OPEN_ENDED,
		percentage: 0,
		isCorrect: false,
	})
	const [error, setError] = useState(false)
	const [helperText, setHelperText] = useState('')
	const onAddQuestionOption = () => {
		return dispatch(addQuestionOptionFromBackEnd(item.Id, surveyTemplateModel))
			.then(() => {
				dispatch(getSurveyTemplateByIdFromBackEnd(surveyTemplate.Id, false))

				onCloseButtonClick()
				setToasterMessageConfig({
					showToaster: true,
					toasterVariant: 'success',
					toasterMessage: 'common.addCorrectly',
				})
			})
			.catch((response) => {
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage: !IsNullOrEmpty(response) ? response : 'common.errorToSave',
					toasterVariant: 'error',
					// name: biddingModel.name,
				})
			})
	}
	/**Verify if can be sabed data*/
	const canBeSaved = () => {
		if (surveyTemplateModel) {
			//if (IsNullOrEmpty(surveyTemplateModel.comments)) return false
			if (IsNullOrEmpty(surveyTemplateModel.title)) return false
			// if (IsNullOrEmpty(surveyTemplateModel.percentage)) return false
			if (surveyTemplateModel.percentage < 0 || surveyTemplateModel.percentage > 100) return false

			return true
		}
		return false
	}

	/**On change properties */
	const onPropertyChange = (event) => {
		let { name, value } = event.target

		if (name === 'percentage') {
			setSurveyTemplateModel({
				...surveyTemplateModel,
				[name]: parseInt(value, 10),
			})

			// Validar la cantidad
			if (value < 0 || value > 100) {
				setError(true)
				setHelperText('El porcentaje debe estar entre 0 y 100.')
			} else {
				setError(false)
				setHelperText('')
			}
		} else {
			setSurveyTemplateModel({
				...surveyTemplateModel,
				[name]: value,
			})
		}
	}

	const onSetQuestionCorrect = (event) => {
		setSurveyTemplateModel({
			...surveyTemplateModel,
			isCorrect: event.target.checked,
		})
	}
	return (
		<LateralPanel onClose={onCloseButtonClick}>
			<PanelHeader
				titlePanel={
					<span>
						<Translate id='survey.template.option.create' />
					</span>
				}
				onCloseButtonClick={onCloseButtonClick}
				icon={
					<Avatar className={classes.avatar}>
						<ViewCarouselIcon />
					</Avatar>
				}
				isIconMaterial={true}
			/>
			<div>
				<div className={classes.containerInformation}>
					<Grid container>
						<Grid item xs={12} className={classes.titleDisplay}>
							<Typography variant='subtitle1' className={classes.margingTopText}>
								Captura la información necesaria para crear una opción, en la pregunta.
							</Typography>
							<Typography variant='subtitle1' color='primary' className={classes.margingTopText}>
								{item ? item.Title : '-'}
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<TextField
								fullWidth
								label={<Translate id='survey.template.question.title' />}
								className={classes.textField}
								onChange={onPropertyChange}
								multiline
								name='title'
								margin='normal'
								variant='outlined'
								value={surveyTemplateModel ? surveyTemplateModel.title : 0}
								inputProps={{ maxLength: CHARACTER_LIMIT_NAME }}
								helperText={`${
									surveyTemplateModel ? surveyTemplateModel.title.length : 0
								}/${CHARACTER_LIMIT_NAME}`}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								fullWidth
								label={<Translate id='survey.template.create.percentage' />}
								className={classes.textField}
								onChange={onPropertyChange}
								type='number'
								name='percentage'
								margin='normal'
								variant='outlined'
								value={surveyTemplateModel ? surveyTemplateModel.percentage : 0}
								InputProps={{
									endAdornment: <InputAdornment position='end'>%</InputAdornment>,
								}}
								error={error} // Cambia el estado de error
								helperText={helperText} // Muestra el mensaje de error
							/>
						</Grid>
						<Grid item xs={12}>
							<FormControlLabel
								control={
									<Switch
										checked={surveyTemplateModel ? surveyTemplateModel.isCorrect : false}
										onChange={onSetQuestionCorrect}
										// value='checkedA'
									/>
								}
								label='¿Está es la opción correcta?'
							/>
						</Grid>

						{/* {SurveyQuestioType.MULTIPLE_CHOICE && <Grid item xs={12}></Grid>}
						{SurveyQuestioType.OPEN_ENDED && <Grid item xs={12}></Grid>}
						{SurveyQuestioType.SINGLE_CHOICE && <Grid item xs={12}></Grid>} */}
					</Grid>
				</div>
				<Grid container>
					<Grid item xs={12} className={classes.containerBtn}>
						<Button
							data-cy='btn-save'
							type='button'
							color='primary'
							variant='outlined'
							onClick={onAddQuestionOption}
							disabled={!canBeSaved() || isSaving}
						>
							{<Translate id='common.save' />}
						</Button>
					</Grid>
				</Grid>
			</div>
		</LateralPanel>
	)
}

const styles = (theme) => ({
	avatar: {
		backgroundColor: theme.palette.secondary.main,
	},
	containerInformation: {
		maxHeight: `calc(100vh - ${155}px)`,
		minHeight: `calc(100vh - ${155}px)`,
		overflowY: 'auto',
		padding: '20px',
	},
	containerBtn: {
		display: 'flex',
		justifyContent: 'center',
	},
})

AddOptionPanel.propTypes = {
	/**
	 * callback executed add new user in the list
	 */
	onSaveButtonClick: propTypes.func,
	/**
	 * open and closedlateral panel for add an new user in customer
	 */
	onCloseButtonClick: propTypes.func,
	/**
	 * Determinate if is saving data
	 */
	isSaving: propTypes.bool.isRequired,
}

AddOptionPanel.defaultProps = {
	onSaveButtonClick: () => console.warn('Callback [onSaveButtonClick] no defined'),
	onCloseButtonClick: () => console.warn('Callback [onCloseButtonClick] no defined'),
	isSaving: false,
}

export default withRouter(withStyles(styles, { withTheme: true })(AddOptionPanel))
