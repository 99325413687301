import React, { useState } from 'react'
import { IconButton, Tooltip, Popper, Paper, ClickAwayListener, MenuList, MenuItem } from '@material-ui/core'
import { Translate } from 'react-localize-redux'
import MoreOptions from '@material-ui/icons/MoreVert'
import InsertChartIcon from '@material-ui/icons/InsertChart'
import DescriptionIcon from '@material-ui/icons/Description'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import uuidv4 from 'uuid/v4'

const ReportOptions = (props) => {
	const { onClickInTransitReport, onClickIncidentsReport, onClickInProcessReport } = props

	const [open, setOpen] = useState(false)

	const onHandleOpenPopper = (value) => {
		setOpen(value)
	}

	return (
		<div style={{ textAlign: 'center' }}>
			<Tooltip title={<Translate id={'transportTender.reportOptions'} />} placement='top'>
				<IconButton color='primary' aria-label='More' onClick={() => onHandleOpenPopper(true)}>
					<MoreOptions />
				</IconButton>
			</Tooltip>
			{open ? (
				<Popper open={open} style={{ zIndex: 50, position: 'absolute' }} transition disablePortal>
					<Paper style={{ backgroundColor: '#fafafa' }}>
						<ClickAwayListener onClickAway={() => onHandleOpenPopper(false)}>
							<MenuList>
								<Tooltip title={<Translate id={'transportTender.incidentsReport'} />} placement='left'>
									<MenuItem key={uuidv4()} onClick={() => onClickIncidentsReport(true)}>
										<InsertChartIcon color='primary' /> {/* Ícono para el reporte de incidentes */}
									</MenuItem>
								</Tooltip>
								<Tooltip
									title={<Translate id={'transportTender.transitRecordReport'} />}
									placement='left'
								>
									<MenuItem key={uuidv4()} onClick={() => onClickInTransitReport(true)}>
										<CloudDownloadIcon color='primary' /> {/* Ícono para el reporte en tránsito */}
									</MenuItem>
								</Tooltip>
								<Tooltip
									title={<Translate id={'transportTender.processRecordReport'} />}
									placement='left'
								>
									<MenuItem key={uuidv4()} onClick={() => onClickInProcessReport(true)}>
										<DescriptionIcon color='primary' /> {/* Ícono para el reporte en proceso */}
									</MenuItem>
								</Tooltip>
							</MenuList>
						</ClickAwayListener>
					</Paper>
				</Popper>
			) : null}
		</div>
	)
}

export default ReportOptions
