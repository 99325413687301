import axios from 'axios'
import { getAuthorizedRequestConfigurationUser } from '../helpers/SessionHelper'

export const IS_SAVING = 'IS_SAVING'
export const START_LOADING_INDIRECT_PRICE_QUOTE_WITHOUT_CODE = 'START_LOADING_INDIRECT_PRICE_QUOTE_WITHOUT_CODE'
export const SET_INDIRECT_INDIRECT_PRICE_QUOTE_WITHOUT_CODE_LIST = 'SET_INDIRECT_INDIRECT_PRICE_QUOTE_WITHOUT_CODE_LIST'
export const SET_INDIRECT_INDIRECT_PRICE_QUOTE_WITHOUT_CODE_DETAIL =
	'SET_INDIRECT_INDIRECT_PRICE_QUOTE_WITHOUT_CODE_DETAIL'

export const SET_INDIRECT_INDIRECT_PRICE_QUOTE_SUPPLIER_DETAIL = 'SET_INDIRECT_INDIRECT_PRICE_QUOTE_SUPPLIER_DETAIL'
export const SET_INDIRECT_INDIRECT_PRICE_QUOTE_SUPPLIER_ITEM = 'SET_INDIRECT_INDIRECT_PRICE_QUOTE_SUPPLIER_ITEM'

export const SET_INDIRECT_PRICE_QUOTE_QUERY = 'SET_INDIRECT_INDIRECT_PRICE_QUOTE_QUERY'
export const SET_INDIRECT_PRICE_QUOTE_PAGINATION = 'SET_INDIRECT_PRICE_QUOTE_PAGINATION'
export const SET_INDIRECT_PRICE_QUOTE_COUNT_PAGINATION = 'SET_INDIRECT_PRICE_QUOTE_COUNT_PAGINATION'

/**
 * Action start loading common data
 * @param {*} isLoading
 */
function isLoadingCommon(isLoading, identifier) {
	return {
		type: START_LOADING_INDIRECT_PRICE_QUOTE_WITHOUT_CODE,
		isLoading: isLoading,
		identifier: identifier,
	}
}

/**
 * Action start saving information
 * @param {*} isLoading
 */
export function isSavingCommon(isSaving, identifier) {
	return {
		type: IS_SAVING,
		isSaving: isSaving,
		identifier: identifier,
	}
}

function setIndirectPriceQuoteWithoutPriceList(items) {
	return {
		type: SET_INDIRECT_INDIRECT_PRICE_QUOTE_WITHOUT_CODE_LIST,
		items: items,
	}
}

function setIndirectPriceQuoteWithoutPriceDetail(item) {
	return {
		type: SET_INDIRECT_INDIRECT_PRICE_QUOTE_WITHOUT_CODE_DETAIL,
		item: item,
	}
}

function setIndirectPriceQuoteWithoutPriceDetailSupplier(item) {
	return {
		type: SET_INDIRECT_INDIRECT_PRICE_QUOTE_SUPPLIER_DETAIL,
		item: item,
	}
}

function setIndirectPriceQuoteWithoutPriceCount(count) {
	return {
		type: SET_INDIRECT_PRICE_QUOTE_COUNT_PAGINATION,
		count: count,
	}
}

function setIndirectPriceQuoteWithoutPriceDetailItem(item) {
	return {
		type: SET_INDIRECT_INDIRECT_PRICE_QUOTE_SUPPLIER_ITEM,
		item: item,
	}
}

/**
 * Get load Indirect Price Quote Without Code  's count
 *
 * @export
 * @returns
 */
export function loadIndirectPriceQuoteWithoutCodeCount() {
	return function (dispatch, getState) {
		let actualCounter = getState().alternativeProvedures.get('indirectPriceQuoteWithoutCodeCount')
		if (actualCounter) {
			return
		}

		let selectedCompany = getState().profile.get('selectedCompany')
		let supplierCode = undefined
		if (getState().oidc.user.profile && getState().oidc.user.profile.company_token) {
			supplierCode = getState().oidc.user.profile.company_code
		}
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/indirectPriceQuoteWithoutCode/count`
		let data = {
			CompanyCode: selectedCompany.get('company_code'),
			SupplierCode: supplierCode,
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setIndirectPriceQuoteWithoutPriceCount(serverResponse.data))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				console.error("Error getting the indirect price quote with out's count", error.response)
			})
	}
}

export function loadIndirectPriceQuoteWithoutCodeFromBackEnd(query, orderBy, sortAscending, page, rowsPerPage) {
	return function (dispatch, getState) {
		dispatch(isLoadingCommon(true, SET_INDIRECT_INDIRECT_PRICE_QUOTE_WITHOUT_CODE_LIST))

		let selectedCompany = getState().profile.get('selectedCompany').toJS()
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let supplierCode = undefined
		if (getState().oidc.user.profile && getState().oidc.user.profile.company_code) {
			supplierCode = getState().oidc.user.profile.company_code
		}
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/indirectPriceQuoteWithoutCode/filters`
		let data = {
			CompanyCode: selectedCompany ? selectedCompany.company_code : '',
			SupplierCode: supplierCode,
			SortBy: orderBy,
			SortAscending: sortAscending,
			Query: query,
			Page: `${page + 1}`,
			RowsPerPage: rowsPerPage,
		}
		return axios
			.post(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setIndirectPriceQuoteWithoutPriceList(serverResponse.data))
					dispatch(isLoadingCommon(false, SET_INDIRECT_INDIRECT_PRICE_QUOTE_WITHOUT_CODE_LIST))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isLoadingCommon(false))
				console.error('Error getting the Load alerts', error.response)
			})
	}
}

export function loadIndirectPriceQuoteWithoutInternalUserDetailFromBackEnd(token) {
	return function (dispatch, getState) {
		dispatch(isLoadingCommon(true, SET_INDIRECT_INDIRECT_PRICE_QUOTE_WITHOUT_CODE_DETAIL))

		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/indirectPriceQuoteWithoutCode/detail/${token}`

		return axios
			.get(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setIndirectPriceQuoteWithoutPriceDetail(serverResponse.data))
					dispatch(isLoadingCommon(false, ''))
					return Promise.resolve(serverResponse.data)
				}
			})
			.catch((error) => {
				dispatch(isLoadingCommon(false, ''))

				console.error('Error getting the Load alerts', error.response)
				return Promise.reject(error.response)
			})
	}
}

export function loadIndirectPriceQuoteWithoutSupplierDetailFromBackEnd(token) {
	return function (dispatch, getState) {
		dispatch(isLoadingCommon(true, SET_INDIRECT_INDIRECT_PRICE_QUOTE_WITHOUT_CODE_DETAIL))

		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/indirectPriceQuoteWithoutCode/detail/${token}/supplier`

		return axios
			.get(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setIndirectPriceQuoteWithoutPriceDetailSupplier(serverResponse.data))
					dispatch(isLoadingCommon(false, ''))
					return Promise.resolve(serverResponse.data)
				}
			})
			.catch((error) => {
				dispatch(isLoadingCommon(false, ''))

				console.error('Error getting the Load alerts', error.response)
				return Promise.reject(error.response)
			})
	}
}

export function updateIndirectPriceQuoteItem(token, itemToken, data) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true, IS_SAVING))

		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/indirectPriceQuoteWithoutCode/${token}/item/${itemToken}`

		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setIndirectPriceQuoteWithoutPriceDetailItem(serverResponse.data))
					dispatch(isSavingCommon(false, IS_SAVING))
					return Promise.resolve(serverResponse.data)
				}
			})
			.catch((error) => {
				dispatch(isSavingCommon(false, IS_SAVING))
				console.error('Error getting the update Indirect Price Quote Item', error.response)
				return Promise.reject(error.response)
			})
	}
}

export function updatePriceQuoteWithOutCodeSupplier(token, data) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true, IS_SAVING))

		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/indirectPriceQuoteWithoutCode/${token}/supplier`

		return axios
			.put(endPoint, data, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setIndirectPriceQuoteWithoutPriceDetailSupplier(serverResponse.data))
					dispatch(isSavingCommon(false, IS_SAVING))
					return Promise.resolve(serverResponse.data)
				}
			})
			.catch((error) => {
				dispatch(isSavingCommon(false, IS_SAVING))
				console.error('Error getting the update Indirect Price Quote supplier', error.response)
				return Promise.reject(error.response)
			})
	}
}

/**
 * Action to change status
 * @param {*} status
 * @param {*} token
 * @param {*} comments
 */
export function changeStatusFromBackEnd(status, token, comments = '', supplier, selectedCompany) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true, IS_SAVING))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/indirectPriceQuoteWithoutCode/supplier/status`
		let command = {
			Status: status,
			Comments: comments,
			Token: token,
			SupplierInformation: supplier, 
			CompanyInformation:selectedCompany

		}
		return axios
			.put(endPoint, command, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(setIndirectPriceQuoteWithoutPriceDetailSupplier(serverResponse.data))
					dispatch(isSavingCommon(false, IS_SAVING))
					return Promise.resolve()
				}
			})
			.catch((error) => {
				dispatch(isSavingCommon(false, IS_SAVING))
				console.error('Error update supplier information', error.response.data)
				return Promise.reject(error.response.data.Message)
			})
	}
}

/**
 * Send quote to development engineer
 *
 * @export
 * @param {*} quoteToken
 * @param {*} supplier
 * @returns
 */
export function generatedNewPDFQuoteWithOutPrice(quoteToken) {
	return (dispatch, getState) => {
		dispatch(isSavingCommon(true, IS_SAVING))
		let data = {}
		let endPoint = `${process.env.REACT_APP_SIP_DEVELOPMENT}/indirectPriceQuoteWithoutCode/${quoteToken}/pdf`
		return axios
			.put(endPoint, data, { responseType: 'arraybuffer' })
			.then((serverResponse) => {
				dispatch(isSavingCommon(false, IS_SAVING))

				if (serverResponse.status === 200) {
					var file = new Blob([serverResponse.data], { type: 'application/pdf' })
					var fileURL = URL.createObjectURL(file)
					window.open(fileURL)
				}
			})
			.catch((error) => {
				dispatch(isSavingCommon(false, IS_SAVING))
				console.error('Error in download quote from supplier ', error.response)
				return Promise.reject()
			})
	}
}

