import { fromJS } from 'immutable'

import * as surverActions from './SurveyTemplatesActions'

/**
 * surverActions initial state
 */
export const surveyTemplateInitialState = fromJS({
	isLoading: true,
	identifier: '',
	categories: [],
	surveyTemplate: null,
	surveyTemplatesPaginated: {
		items: [],
		pageNumber: 0,
		pageSize: 30,
		total: 0,
		sort: {
			by: 'Title',
			direction: 'desc',
		},
	},
})

export default function surveyReducer(state = surveyTemplateInitialState, action) {
	switch (action.type) {
		case surverActions.IS_LOADING: {
			return state.merge({
				isLoading: action.isLoading,
				identifier: action.identifier,
			})
		}
		case surverActions.IS_SAVING: {
			return state.merge({
				isSaving: action.isSaving,
				identifier: action.identifier,
			})
		}
		case surverActions.SET_SURVEY_TEMPLATES_LIST: {
			return state.merge({
				surveyTemplatesPaginated: fromJS(action.data),
				isSaving: false,
				identifier: '',
			})
		}
		case surverActions.ADD_SURVEY_TEMPLATE: {
			return state
				.updateIn(['surveyTemplatesPaginated', 'items'], (allItems) =>
					addSurveyTemplate(allItems, fromJS(action.item))
				)
				.set('isSaving', false)
		}

		case surverActions.ADD_SURVEY_SECTION: {
			return state
				.updateIn(['surveyTemplate', 'Sections'], (allSections) =>
					addSurveySection(allSections, fromJS(action.item))
				)
				.set('isSaving', false)
		}
		case surverActions.EDIT_SURVEY_TEMPLATE: {
			return state.merge({
				surveyTemplate: fromJS(action.item),
				isSaving: false,
			})
		}
		case surverActions.SURVEY_TEMPLATE_DETAIL: {
			return state.merge({
				surveyTemplate: fromJS(action.item),
				isSaving: false,
			})
		}
		case surverActions.DELETE_SURVEY_SECTION: {
			return state
				.updateIn(['surveyTemplate', 'Sections'], (allSections) =>
					allSections.filter((item) => item.get('Id') !== action.id)
				)
				.set('isSaving', false)
		}
		default:
			return state
	}
}

const addSurveyTemplate = (allSurveyTemplate, surveyTemplate) => {
	let indexOfSurveyTemplate = allSurveyTemplate.findIndex((d) => d.get('Id') === surveyTemplate.get('Id'))
	if (indexOfSurveyTemplate >= 0) {
		return allSurveyTemplate.setIn([indexOfSurveyTemplate], surveyTemplate)
	}
	return allSurveyTemplate.push(surveyTemplate)
}

const addSurveySection = (allSectionTemplate, surveyTemplate) => {
	let indexOfSurveyTemplate = allSectionTemplate.findIndex((d) => d.get('Id') === surveyTemplate.get('Id'))
	if (indexOfSurveyTemplate >= 0) {
		console.log('estoy actualizando los datos', indexOfSurveyTemplate)

		return allSectionTemplate.setIn([indexOfSurveyTemplate], surveyTemplate)
	}
	return allSectionTemplate.push(surveyTemplate)
}
