import React from 'react'
/** Translations imports section */
import { Translate } from 'react-localize-redux'
/** Material-UI imports section */
import {} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import SuppliersIcon from '@material-ui/icons/People'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import DeleteIcon from '@material-ui/icons/DeleteOutline'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import { Grid } from '@material-ui/core'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'

// import PercentIcon from '@material-ui/icons/Percent';
import LoadingPage from '../../common/LoadingPage'
import SuppliersCompaniesSelector from '../../officialNotices/components/SuppliersCompaniesSelector'
const SupplierCard = (props) => {
	const { classes, isLoading } = props
	//functions
	const { onRemoveSupplierList, onChangeCheckBox, onChangeSupplierList } = props
	//objects
	const { surveyData } = props
	//arrays
	const { suppliers = [] } = props

	if (isLoading) return <LoadingPage />

	const onGetSupplierList = () => {
		if (suppliers) {
			return (
				<List>
					{suppliers.map((supplier) => {
						return (
							<div key={supplier.name}>
								<ListItem divider>
									<ListItemText
										primary={
											<Typography variant='caption' gutterBottom>
												{supplier.Name}
											</Typography>
										}
									/>
									<ListItemSecondaryAction>
										<IconButton
											id={supplier.code}
											onClick={(event) => {
												onRemoveSupplierList(supplier.Code)
											}}
										>
											<DeleteIcon color='error' />
										</IconButton>
									</ListItemSecondaryAction>
								</ListItem>
							</div>
						)
					})}
				</List>
			)
		} else {
			return null
		}
	}
	return (
		<Card className={classes.card} style={{ height: '79vh' }}>
			<CardHeader
				avatar={
					<Avatar className={classes.userAvatar}>
						<SuppliersIcon />
					</Avatar>
				}
				title={<Translate id='officialNotices.showNextSuppliers' />}
				subheader={<Translate id='officialNotices.addSupplier' />}
			/>
			<CardContent>
				<Grid container spacing={24}>
					<Grid item xs={12}>
						<div className={classes.formatCheck}>
							<FormControl component='fieldset' className={classes.formControl}>
								<FormControlLabel
									control={
										<Checkbox
											checked={surveyData ? surveyData.optionAll : false}
											onChange={(event) => {
												onChangeCheckBox('optionAll', event.target.checked)
											}}
										/>
									}
									label={<Translate id='officialNotices.optionAll' />}
								/>
							</FormControl>

							<FormControl component='fieldset' className={classes.formControl}>
								<FormControlLabel
									control={
										<Checkbox
											checked={surveyData ? surveyData.optionNationals : false}
											onChange={(event) => {
												onChangeCheckBox('optionNationals', event.target.checked)
											}}
										/>
									}
									label={<Translate id='officialNotices.optionNationals' />}
								/>
							</FormControl>

							<FormControl component='fieldset' className={classes.formControl}>
								<FormControlLabel
									control={
										<Checkbox
											checked={surveyData ? surveyData.optionForeigns : false}
											onChange={(event) => {
												onChangeCheckBox('optionForeigns', event.target.checked)
											}}
										/>
									}
									label={<Translate id='officialNotices.optionExtranjeros' />}
								/>
							</FormControl>
						</div>
					</Grid>
					<Grid item xs={12}>
						<SuppliersCompaniesSelector
							className={classes.textField}
							onSupplierSelected={(supplier) => {
								onChangeSupplierList(supplier)
							}}
						/>
					</Grid>
					<Grid item xs={12}>
						<List>
							{onGetSupplierList()}
							{suppliers.length === 0 && (
								<div>
									<ListItem>
										<ListItemText
											primary={
												<Typography variant='subtitle2' gutterBottom>
													<Translate id='surveys.noSuppliersToSendEthicCode' />
												</Typography>
											}
										/>
									</ListItem>
									<Divider />
								</div>
							)}
						</List>
					</Grid>
				</Grid>
			</CardContent>
			<CardActions className={classes.actions} disableActionSpacing></CardActions>
		</Card>
	)
}

const styles = (theme) => ({
	userAvatar: {
		backgroundColor: theme.palette.secondary.main,
	},
	textField: {
		marginLeft: theme.spacing.unit,
		marginRight: theme.spacing.unit,
		width: 240,
	},
	// toolBar: {
	// 	backgroundColor: 'red',
	// },
	// button: {
	// 	margin: theme.spacing.unit,
	// },
	// errorMessage: {
	// 	color: theme.palette.common.white,
	// },
	formatCheck: {
		display: 'flex',
		marginLeft: theme.spacing.unit,
		width: 460,
	},
})

export default withStyles(styles)(SupplierCard)
