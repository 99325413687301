import React, { Component } from 'react'
import autoBind from 'auto-bind'
import { withRouter } from 'react-router-dom'
import { Translate } from 'react-localize-redux'
import moment from 'moment'

/** Material-UI imports section */
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Toolbar from '@material-ui/core/Toolbar'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'

/** Import component section */
import Dialog from './../../../common/Dialog'
import DialogActions from './../../../common/DialogActions'
import Evidence from './../../../common/Evidence'
import DatePicker from './../../../common/DateTimePicker/DatePicker'
/** Actions and helpers imports section */
import { SHOW_EDIT_VIEW, COMPANY_IMBERA } from '../../../../store/helpers/ResourcesHelper'
import { IsNullOrEmpty } from '../../../../store/helpers/StringHelper'
import { MenuItem, Select } from '@material-ui/core'
/**
 * Class compoenent to add an employee
 *
 * @class AddSocialInsurancePaymentDialog
 * @extends {Component}
 */
class AddSocialInsurancePaymentDialog extends Component {
	/**
	 *Creates an instance of DialogActions.
	 * @param {*} props
	 * @memberof DialogActions
	 */
	constructor(props) {
		super(props)
		autoBind(this)
		this.state = this.initialState()
	}

	/**
	 * return initial state of component
	 *
	 * @returns
	 * @memberof AddSocialInsurancePaymentDialog
	 */
	initialState() {
		return {
			date_start: null,
			date_end: null,
			payment_description: '',
			file_sua: [],
			file_proof_payment: [],
			date_start_Sua: null,
			date_end_Sua: null,
			endDates: [],
			document_type: '',
		}
	}

	/**
	 * Handle update properties to set data in local state
	 *
	 * @param {*} property
	 * @param {*} data
	 * @memberof AddSocialInsurancePaymentDialog
	 */
	updateProperty(property, data) {
		if (property === 'date_start_Sua' && this.props.actualCompany.company_code === COMPANY_IMBERA) {
			this.setState({
				payment_description: 'Pago de mes ' + this.state.date_start_Sua,
			})
		}
		this.setState({
			[property]: data,
		})
	}

	updatePropertySua(property, data, values) {
		var documentType = this.state.document_type;
		var endValues = []
		if (parseInt(data.target.value) <= 10) {
			endValues.push(values.find((value) => parseInt(value.value) === parseInt(data.target.value) + 1))
			endValues.push(values.find((value) => parseInt(value.value) === parseInt(data.target.value) + 2))
		} else if (parseInt(data.target.value) === 11) {
			endValues.push(values.find((value) => parseInt(value.value) === parseInt(data.target.value) + 1))
			endValues.push(values.find((value) => parseInt(value.value) === 1))
			endValues[1].label = endValues[1].label.concat(' (' + moment().add(1, 'years').format('YYYY') + ')')
		} else if (parseInt(data.target.value) === 12) {
			endValues.push(values.find((value) => parseInt(value.value) === 1))
			endValues[0].label = endValues[0].label.concat(' (' + moment().add(1, 'years').format('YYYY') + ')')
			endValues.push(values.find((value) => parseInt(value.value) === 2))
			endValues[1].label = endValues[1].label.concat(' (' + moment().add(1, 'years').format('YYYY') + ')')
		}
		console.log({property,data,values})
		this.setState({
			[property]: data.target.value,
		})
		if (property !== 'date_end_Sua') {
			if (this.props.actualCompany.company_code === COMPANY_IMBERA) {
				if(documentType === 'ALTA'){
					// endValues = [endValues[0]]
					this.setState({
						endDates: endValues,
						date_end_Sua: endValues[0].value,
						payment_description: 'Pago de mes ' + values[parseInt(data.target.value) - 1].label,
					})
				} else{
					this.setState({
						endDates: endValues,
						date_end_Sua: endValues[1].value,
						payment_description: 'Pago de mes ' + values[parseInt(data.target.value) - 1].label,
					})
				}
			} else {
				this.setState({
					endDates: endValues,
					date_end_Sua: endValues[1].value,
					payment_description: 'SUA y pago de mes ' + values[parseInt(data.target.value) - 1].label,
				})
			}
		}
	}

	updatePropertyDocType(property, data) {
		this.setState({
			[property]: data.target.value,
		})
		var endDate = this.state.endDates;
		if(data.target.value === 'ALTA' && endDate.length > 0){
			this.setState({
				endDates: [endDate[0]],
			})
		}
	}

	/**
	 * Handle event upload files of devidence componet to add files to list
	 *
	 * @param {*} files
	 * @returns
	 * @memberof AddSocialInsurancePaymentDialog
	 */
	uploadFiles(files, key) {
		let filesList = this.state[key].concat(files[0])
		if (files.length > 0) {
			for (var index = 1; index < files.length; index++) {
				filesList.push(files[index])
			}
		}

		this.setState({
			[key]: filesList,
		})
		return Promise.resolve('Ok')
	}

	/**
	 * Handle event remove files of evidence componet to remove files of list
	 *
	 * @param {*} fileName
	 * @returns
	 * @memberof AddSocialInsurancePaymentDialog
	 */
	removeFiles(fileName, key) {
		let newList = this.state[key].filter((files) => {
			return files.name !== fileName
		})
		this.setState({
			[key]: newList,
		})
		return Promise.resolve('Ok')
	}

	/**
	 * method to call action and add payment
	 *
	 * @memberof AddSocialInsurancePaymentDialog
	 */
	onAddPayment() {
		this.props.addPayment(this.state).then((response) => {
			if (response === 'Ok') this.handleCloseCancel()
		})
	}

	/**
	 * Handle  Close or cancel event
	 *
	 * @memberof AddSocialInsurancePaymentDialog
	 */
	handleCloseCancel() {
		this.setState(this.initialState(), () => this.props.onClose())
	}

	/**
	 * Method to validate if required properties has data
	 *
	 * @returns
	 * @memberof AddSocialInsurancePaymentDialog
	 */
	isValidToSave() {
		if (
			this.state.payment_description &&
			!IsNullOrEmpty(this.state.payment_description) &&
			this.state.date_start_Sua &&
			!IsNullOrEmpty(this.state.date_start_Sua) &&
			this.state.date_end_Sua &&
			!IsNullOrEmpty(this.state.date_end_Sua) &&
			this.state.file_sua &&
			this.state.file_sua.length > 0 &&
			this.state.file_proof_payment &&
			this.state.file_proof_payment.length > 0
		) {
			if (this.props.actualCompany.company_code === COMPANY_IMBERA) {
				if (this.state.document_type && !IsNullOrEmpty(this.state.document_type)) return true
				else return false
			} else return true
		}
		return false
	}

	/**
	 * Method to render text field
	 *
	 * @param {*} textFieldId
	 * @param {*} textFieldTranslateId
	 * @returns
	 * @memberof AddSocialInsurancePaymentDialog
	 */
	renderTextArea(textFieldId, textFieldTranslateId) {
		let isActiveText = false
		if (this.props.actualCompany.company_code === COMPANY_IMBERA) isActiveText = true
		return (
			<TextField
				id={textFieldId}
				label={<Translate id={textFieldTranslateId} />}
				value={this.state[textFieldId] ? this.state[textFieldId] : ''}
				margin='normal'
				type={'text'}
				fullWidth
				required
				onBlur={(event) => this.updateProperty(textFieldId, event.target.value)}
				// onChange={this.updateProperty(textFieldId)}
				onChange={(event) => this.updateProperty(textFieldId, event.target.value)}
				multiline
				variant='outlined'
				rowsMax={4}
				disabled={isActiveText}
			/>
		)
	}

	/**
	 * Render datetime component
	 *
	 * @param {*} key
	 * @param {*} translationId
	 * @param {number} [xs=12]
	 * @returns
	 * @memberof AddSocialInsurancePaymentDialog
	 */
	renderDateTime(key, translationId, xs = 12) {
		return (
			<Grid item xs={xs} className={this.props.classes.textField}>
				<Typography variant='caption' color={'textSecondary'}>
					<Translate id={translationId} />*
				</Typography>
				<DatePicker
					onChangeRange={(event) => {
						this.updateProperty(key, event)
					}}
					startDate={this.state[key] ? this.state[key] : ''}
				/>
			</Grid>
		)
	}
	renderDocumentType(key) {
		const currencies = [
			{
				value: 'SUA',
				label: 'SUA',
			},
			{
				value: 'ALTA',
				label: 'ALTA',
			},
		]
		return (
			<Grid item xs={8}>
				<Typography>
					<Translate id='contractorsModule.documentType' />
					{'   '}
					<Select
						id={key}
						value={this.state[key] ? this.state[key] : ''}
						onChange={(event) => {
							this.updatePropertyDocType(key, event)
						}}
					>
						{currencies.map((option) => (
							<MenuItem key={option.value} value={option.value}>
								{option.label}
							</MenuItem>
						))}
					</Select>
				</Typography>
			</Grid>
		)
	}
	renderDateTimeSUA(key, translationId, xs = 12, SelectEndDate = false) {
		const currencies = [
			{
				value: '01',
				label: 'Enero',
			},
			{
				value: '02',
				label: 'Febrero',
			},
			{
				value: '03',
				label: 'Marzo',
			},
			{
				value: '04',
				label: 'Abril',
			},
			{
				value: '05',
				label: 'Mayo',
			},
			{
				value: '06',
				label: 'Junio',
			},
			{
				value: '07',
				label: 'Julio',
			},
			{
				value: '08',
				label: 'Agosto',
			},
			{
				value: '09',
				label: 'Septiembre',
			},
			{
				value: '10',
				label: 'Octubre',
			},
			{
				value: '11',
				label: 'Noviembre',
			},
			{
				value: '12',
				label: 'Diciembre',
			},
		]
console.log({endDates: this.state.endDates, currencies})
		return (
			<Grid item xs={xs} className={this.props.classes.textField}>
				<Typography variant='caption' color={'textSecondary'}>
					<Translate id={translationId} /> SUA*
				</Typography>
				<Select
					id={key}
					value={this.state[key] ? this.state[key] : ''}
					onChange={(event) => {
						this.updatePropertySua(key, event, currencies)
					}}
				>
					{!SelectEndDate &&
						currencies.map((option) => (
							<MenuItem key={option.value} value={option.value}>
								{option.label}
							</MenuItem>
						))}
					{SelectEndDate &&
						this.state.endDates.map((option) => (
							<MenuItem key={option.value} value={option.value}>
								{option.label}
							</MenuItem>
						))}
				</Select>
			</Grid>
		)
	}
	/**
	 * Render Evidence component
	 *
	 * @param {*} key
	 * @param {*} translationId
	 * @param {number} [xs=12]
	 * @returns
	 * @memberof AddSocialInsurancePaymentDialog
	 */
	renderEvidence(key, translationId, xs = 12) {
		return (
			<Grid item xs={xs} className={this.props.classes.checkBox}>
				<Typography variant='subtitle2' color={'textSecondary'}>
					<Translate id={translationId} /> PDF *
				</Typography>
				<Evidence
					baseApi={process.env.REACT_APP_SIP_URL_FILES}
					removeFiles={(fileName) => this.removeFiles(fileName, key)}
					uploadFiles={(files) => this.uploadFiles(files, key)}
					files={this.state[key] ? this.state[key] : []}
					acceptedFiles={['pdf']}
					disabledLink
					maxFiles={1}
				/>
			</Grid>
		)
	}

	render() {
		const { classes } = this.props
		return (
			<Dialog
				open={this.props.open}
				onClose={() => this.handleCloseCancel()}
				header={
					<Toolbar>
						<Typography variant='h6'>
							{<Translate id='contractorsModule.addSocialSecurityPayment' />}
						</Typography>
					</Toolbar>
				}
				actions={
					<div>
						{!this.isValidToSave() && (
							<Typography variant='caption' color='error'>
								{<Translate id='contractorsModule.loadIMSSRegister' />}
							</Typography>
						)}
						<DialogActions
							saveButtonTranslate='contractorsModule.addEmployees'
							view={SHOW_EDIT_VIEW}
							classes={classes}
							onCancel={() => this.handleCloseCancel()}
							onClose={() => this.handleCloseCancel()}
							onSaveItem={this.onAddPayment}
							isSaving={this.props.isSaving}
							canBeSaved={this.isValidToSave()}
						/>
					</div>
				}
			>
				<Grid container className={classes.modal} justify='space-between'>
					{this.props.actualCompany.company_code === COMPANY_IMBERA &&
						this.renderDocumentType('document_type')}

					{this.renderDateTimeSUA('date_start_Sua', 'contractorsModule.startDate', 5, false)}
					{this.renderDateTimeSUA('date_end_Sua', 'contractorsModule.endDate', 5, true)}
					{this.state.date_start_Sua ? (
						<Typography variant='subtitle1'>
							Mes de pago del 17 de {moment(this.state.date_start_Sua, 'MM').format('MMMM')} al 17 de{' '}
							{moment(this.state.date_end_Sua, 'MM').format('MMMM')}
						</Typography>
					) : (
						''
					)}

					<Typography variant='caption' color='error'>
						<Translate id='contractorsModule.sameMonthSUA' />
					</Typography>
					{this.renderTextArea('payment_description', 'contractorsModule.paymentDescription')}
					{this.renderEvidence('file_sua', 'contractorsModule.fileSUA')}
					{this.renderEvidence('file_proof_payment', 'contractorsModule.depositReceipt')}
				</Grid>
			</Dialog>
		)
	}
}

const styles = (theme) => ({
	modal: {
		marginLeft: theme.spacing.unit * 2,
		marginRight: theme.spacing.unit * 2,
		maxWidth: 500,
	},
})

export default withRouter(withStyles(styles, { withTheme: true })(AddSocialInsurancePaymentDialog))
