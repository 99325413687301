///Import react section
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'
import { Translate } from 'react-localize-redux'

/** Material-UI imports section */
/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout'
import Toaster from '../common/Toaster'
/**Import actions section */

import { getSurveyByIdFromBackEnd } from '../../store/survey/SurveyActions'
import LoadingPage from '../common/LoadingPage'
import ShowDesktop from './show/ShowDesktop'

const Create = (props) => {
	/**Render hooks section */
	const dispatch = useDispatch()

	/**Redux store information section*/

	const isLoading = useSelector((state) => state.surveys.get('isLoading'))
	// const isSaving = useSelector((state) => state.surveys.get('isSaving'))

	/**Redux store information section*/
	const survey = useSelector((state) => (state.surveys.get('survey') ? state.surveys.get('survey').toJS() : null))
	const [toasterMessageConfig, setToasterMessageConfig] = useState({
		showToaster: false,
		toasterMessage: null,
		toasterVariant: '',
		name: '',
	})

	/**Use effect section */
	useEffect(() => {
		dispatch(getSurveyByIdFromBackEnd(props.match.params.id))
	}, [dispatch, props.match.params.id])


	if (isLoading) return <LoadingPage />
    
	return (
		<>
			<ShowDesktop
				//objects
				survey={survey}
			/>
			<Toaster
				message={<Translate id={toasterMessageConfig.toasterMessage} />}
				open={toasterMessageConfig.showToaster}
				variant={toasterMessageConfig.toasterVariant}
				onClose={() => {
					setToasterMessageConfig({
						showToaster: false,
						toasterMessage: '',
						toasterVariant: '',
					})
				}}
			/>
		</>
	)
}

const styles = (theme) => ({})

export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme: true })(Create)))
