/**Import react section */
import React from 'react'
import propTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import moment from 'moment'

/** Material-UI imports section */
import { withStyles, Grid } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Toolbar from '@material-ui/core/Toolbar'
//Import compinents section
import Dialog from '../../common/Dialog'
import DialogActions from '../../common/DialogActions'

import { SHOW_EDIT_VIEW } from '../../../store/helpers/ResourcesHelper'
import DatePicker from '../../common/DateTimePicker/DatePicker'
import HourPickers from '../common/HourPickers'

const DownloadInTransitFolio = (props) => {
	/**props section */
	const { classes, onDownloadReport } = props

	const [dateConfigurationModel, setDateConfigurationModelModel] = React.useState({
		endDate: null,
		startDate: null,
        endHour: null,
        startHour:null,
	})

	const canBeSavedDates = () => {
		if (dateConfigurationModel.endDate === null) return false
		if (dateConfigurationModel.startDate === null) return false
        if (dateConfigurationModel.endHour === null) return false
        if (dateConfigurationModel.dtartHour === null) return false
		return true
	}

	const onSaveButtonClick = () => {
		onDownloadReport(dateConfigurationModel.startDate, dateConfigurationModel.endDate)
        setDateConfigurationModelModel({
            endDate: null,
            startDate: null,
            endHour: null,
            startHour:null,
        })
	}

	const onChangeDate = (property, data) => {
		if (property === 'startDate') {
			setDateConfigurationModelModel({
				...dateConfigurationModel,
				[property]: data,
				endDate: null,
			})
		} else {
			setDateConfigurationModelModel({
				...dateConfigurationModel,
				[property]: data,
			})
		}
	}

    const updateProperty = (property, value) => {
		let dateUpdate = { ...dateConfigurationModel }
		dateUpdate[property] = value
		if (property === 'startHour') {
			var day = moment(dateUpdate.startDate, 'DD/MM/YYYY')
			var splitTime = value.split(/:/)
			day.hours(parseInt(splitTime[0])).minutes(parseInt(splitTime[1])).seconds(0).milliseconds(0)
			dateUpdate['startDate'] = day
		}
		if (property === 'endHour') {
			var dayIn = moment(dateUpdate.endDate, 'DD/MM/YYYY')
			var splitTimeIn = value.split(/:/)
			dayIn.hours(parseInt(splitTimeIn[0])).minutes(parseInt(splitTimeIn[1])).seconds(0).milliseconds(0)
			dateUpdate['endDate'] = dayIn
		}
		setDateConfigurationModelModel(dateUpdate)
	}

	return (
		<Dialog
			open={props.open}
			onClose={props.onClose}
			header={
				<Toolbar>
					<Typography variant='h6'>Descargar folios en proceso</Typography>
				</Toolbar>
			}
			actions={
				<DialogActions
					view={SHOW_EDIT_VIEW}
					classes={classes}
					onCancel={props.onClose}
					onClose={props.onClose}
					onSaveItem={onSaveButtonClick}
					isSaving={props.isSaving}
					canBeSaved={canBeSavedDates()}
				/>
			}
		>
			<Grid container>
				
				<Grid item xs={6}>
					<Typography variant='subtitle2' color='primary' className={classes.titleText}>
						Fecha y Hora de inicio de periodo
					</Typography>
					<DatePicker
						opens={'left'}
						onChangeRange={(event) => onChangeDate('startDate', event)}
						startDate={dateConfigurationModel.startDate ? moment(dateConfigurationModel.startDate) : ''}
					/>
				</Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={5}>
						<div style={{ marginTop: '10px' }}>
							<HourPickers
								name='startHour'
								value={dateConfigurationModel.startHour ? dateConfigurationModel.startHour : null}
								onChange={(event) => updateProperty(event.target.name, event.target.value)}
							/>
						</div>
				</Grid>

				<Grid item xs={6}>
					<Typography variant='subtitle2' color='primary' className={classes.titleText}>
						Fecha y Hora de fin de periodo
					</Typography>
					<DatePicker
						opens={'left'}
						onChangeRange={(event) => onChangeDate('endDate', event)}
						startDate={
							dateConfigurationModel.endDate ? moment(dateConfigurationModel.endDate, 'DD/MM/YYYY') : ''
						}
					/>
				</Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={5}>
						<div style={{ marginTop: '10px' }}>
							<HourPickers
								name='endHour'
								value={dateConfigurationModel.endHour ? dateConfigurationModel.endHour : null}
								onChange={(event) => updateProperty(event.target.name, event.target.value)}
							/>
						</div>
				</Grid>
			</Grid>
		</Dialog>
	)
}

const styles = (theme) => ({})

DownloadInTransitFolio.propTypes = {
	open: propTypes.bool.isRequired,
}

DownloadInTransitFolio.defaultProps = {
	open: false,
}

export default withRouter(withStyles(styles, { withTheme: true })(DownloadInTransitFolio))