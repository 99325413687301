/**Import react section */
import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import propTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
/**Import MaterialUi section */
import { Grid, Avatar, Button, Typography, TextField, InputAdornment } from '@material-ui/core'
import ViewCarouselIcon from '@material-ui/icons/ViewCarousel'
/**Import components section */
import LateralPanel from '../../../common/LateralPanelComponents/LateralPanel'
import PanelHeader from '../../../common/LateralPanelComponents/PanelHeader'
/**Import store section */
import { IsNullOrEmpty } from '../../../../store/helpers/StringHelper'
import {
	getSurveyTemplateByIdFromBackEnd,
	updateSurverQuestionFromBackEnd,
} from '../../../../store/surveyTemplates/SurveyTemplatesActions'

import Select from 'react-select'
import { GetQuestionTypeOptions } from '../../../../store/helpers/SelectOptions'
import { SurveyQuestioType } from '../../../../store/helpers/AppConstants'

const EditQuestionPanel = (props) => {
	//objects props sections
	const { item, surveyTemplate } = props
	const dispatch = useDispatch()

	const CHARACTER_LIMIT_NAME = 100

	/**props section */
	const { onCloseButtonClick, isSaving, classes, setToasterMessageConfig } = props

	const [surveyTemplateModel, setSurveyTemplateModel] = useState({
		title: '',
		type: SurveyQuestioType.OPEN_ENDED,
		percentage: 0,
		recommendation: '',
		warning: '',
	})

	// /**Use effect section */
	useEffect(() => {
		if (item) {
			setSurveyTemplateModel({
				...surveyTemplateModel,
				title: item ? item.Title : '',
				type: item ? item.Type : '',
				percentage: item ? item.Percentage : '',
				recommendation: item ? item.Recommendation : '',
				warning: item ? item.Warning : '',
			})
		}
	}, [])

	console.log('surveyTemplateModel', surveyTemplateModel)
	const [error, setError] = useState(false)
	const [helperText, setHelperText] = useState('')

	const onEditSurveyQuestion = () => {
		return dispatch(updateSurverQuestionFromBackEnd(item.Id, surveyTemplateModel))
			.then(() => {
				dispatch(getSurveyTemplateByIdFromBackEnd(surveyTemplate.Id, false))

				onCloseButtonClick()
				setToasterMessageConfig({
					showToaster: true,
					toasterVariant: 'success',
					toasterMessage: 'common.addCorrectly',
				})
			})
			.catch((response) => {
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage: !IsNullOrEmpty(response) ? response : 'common.errorToSave',
					toasterVariant: 'error',
					// name: biddingModel.name,
				})
			})
	}
	/**Verify if can be sabed data*/
	const canBeSaved = () => {
		if (surveyTemplateModel) {
			//if (IsNullOrEmpty(surveyTemplateModel.comments)) return false
			if (IsNullOrEmpty(surveyTemplateModel.title)) return false
			if (IsNullOrEmpty(surveyTemplateModel.type)) return false
			// if (IsNullOrEmpty(surveyTemplateModel.percentage)) return false
			if (surveyTemplateModel.percentage < 0 || surveyTemplateModel.percentage > 100) return false
			return true
		}
		return false
	}

	/**On change properties */
	const onPropertyChange = (event) => {
		let { name, value } = event.target

		if (name === 'percentage') {
			setSurveyTemplateModel({
				...surveyTemplateModel,
				[name]: parseInt(value, 10),
			})

			// Validar la cantidad
			if (value < 0 || value > 100) {
				setError(true)
				setHelperText('El porcentaje debe estar entre 0 y 100.')
			} else {
				setError(false)
				setHelperText('')
			}
		} else {
			setSurveyTemplateModel({
				...surveyTemplateModel,
				[name]: value,
			})
		}
	}

	const onChangeQuestionType = (item) => {
		setSurveyTemplateModel({
			...surveyTemplateModel,
			type: item.value,
		})
	}

	return (
		<LateralPanel onClose={onCloseButtonClick}>
			<PanelHeader
				titlePanel={
					<span>
						<Translate id='survey.template.question.edit' />
					</span>
				}
				onCloseButtonClick={onCloseButtonClick}
				icon={
					<Avatar className={classes.avatar}>
						<ViewCarouselIcon />
					</Avatar>
				}
				isIconMaterial={true}
			/>
			<div>
				<div className={classes.containerInformation}>
					<Grid container>
						<Grid item xs={12} className={classes.titleDisplay}>
							<Typography variant='subtitle1' color='primary' className={classes.margingTopText}>
								<Translate id='survey.template.question.message' />
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<TextField
								fullWidth
								label={<Translate id='survey.template.question.title' />}
								className={classes.textField}
								onChange={onPropertyChange}
								multiline
								name='title'
								margin='normal'
								variant='outlined'
								value={surveyTemplateModel ? surveyTemplateModel.title : 0}
								inputProps={{ maxLength: CHARACTER_LIMIT_NAME }}
								helperText={`${
									surveyTemplateModel ? surveyTemplateModel.title.length : 0
								}/${CHARACTER_LIMIT_NAME}`}
							/>
						</Grid>
						<Grid item xs={12}>
							<Select
								className={classes.documentTypeSelect}
								options={GetQuestionTypeOptions()}
								onChange={onChangeQuestionType}
								styles={{
									// menu: (base) => ({ ...base, zIndex: 10 })
									menu: (base) => ({ ...base, zIndex: 9999, maxHeight: 200 }),
									menuList: (base) => ({ ...base, maxHeight: 200, paddingTop: 0 }),
									menuPortal: (base) => ({ ...base, zIndex: 9999 }), /// THIS IS TO SHOW MENU OVER MODAL
								}}
								// menuPosition='fixed' //THIS IS REQUIRED IN MODAL!!
								// menuPlacement={'auto'}
								value={GetQuestionTypeOptions().find(
									(x) => x.value === parseInt(surveyTemplateModel.type, 10)
								)}
								// // isLoading={loadingWorkshops}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								fullWidth
								label={<Translate id='survey.template.create.percentage' />}
								className={classes.textField}
								onChange={onPropertyChange}
								type='number'
								name='percentage'
								margin='normal'
								variant='outlined'
								value={surveyTemplateModel ? surveyTemplateModel.percentage : 0}
								InputProps={{
									endAdornment: <InputAdornment position='end'>%</InputAdornment>,
								}}
								error={error} // Cambia el estado de error
								helperText={helperText} // Muestra el mensaje de error
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								fullWidth
								label={<Translate id='survey.template.question.warning' />}
								className={classes.textField}
								onChange={onPropertyChange}
								name='warning'
								margin='normal'
								variant='outlined'
								value={surveyTemplateModel ? surveyTemplateModel.warning : ''}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								fullWidth
								label={<Translate id='survey.template.question.recommendation' />}
								className={classes.textField}
								onChange={onPropertyChange}
								name='recommendation'
								margin='normal'
								variant='outlined'
								value={surveyTemplateModel ? surveyTemplateModel.recommendation : ''}
							/>
						</Grid>
					</Grid>
				</div>
				<Grid container>
					<Grid item xs={12} className={classes.containerBtn}>
						<Button
							data-cy='btn-save'
							type='button'
							color='primary'
							variant='outlined'
							onClick={onEditSurveyQuestion}
							disabled={!canBeSaved() || isSaving}
						>
							{<Translate id='common.save' />}
						</Button>
					</Grid>
				</Grid>
			</div>
		</LateralPanel>
	)
}

const styles = (theme) => ({
	avatar: {
		backgroundColor: theme.palette.secondary.main,
	},
	containerInformation: {
		maxHeight: `calc(100vh - ${155}px)`,
		minHeight: `calc(100vh - ${155}px)`,
		overflowY: 'auto',
		padding: '20px',
	},
	containerBtn: {
		display: 'flex',
		justifyContent: 'center',
	},
	titleDisplay: {
		display: 'flex',
	},
})

EditQuestionPanel.propTypes = {
	/**
	 * callback executed add new user in the list
	 */
	onSaveButtonClick: propTypes.func,
	/**
	 * open and closedlateral panel for add an new user in customer
	 */
	onCloseButtonClick: propTypes.func,
	/**
	 * Determinate if is saving data
	 */
	isSaving: propTypes.bool.isRequired,
}

EditQuestionPanel.defaultProps = {
	onSaveButtonClick: () => console.warn('Callback [onSaveButtonClick] no defined'),
	onCloseButtonClick: () => console.warn('Callback [onCloseButtonClick] no defined'),
	isSaving: false,
}

export default withRouter(withStyles(styles, { withTheme: true })(EditQuestionPanel))
