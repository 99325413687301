/**Import react section */
import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import propTypes from 'prop-types'
import { Translate } from 'react-localize-redux'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
/**Import MaterialUi section */
import { Grid, Avatar, Button, Typography, TextField } from '@material-ui/core'
import ViewCarouselIcon from '@material-ui/icons/ViewCarousel'
/**Import components section */
import LateralPanel from '../../../common/LateralPanelComponents/LateralPanel'
import PanelHeader from '../../../common/LateralPanelComponents/PanelHeader'
/**Import store section */
import { BACKGROUND_COLOR_GRAY } from '../../../../store/helpers/StatusColorConstants'
import { IsNullOrEmpty } from '../../../../store/helpers/StringHelper'
import { updateSurveyTemplateFromBackEnd } from '../../../../store/surveyTemplates/SurveyTemplatesActions'

const EditSurveyTemplatePanel = (props) => {
	const dispatch = useDispatch()

	const CHARACTER_LIMIT_NAME = 100
	const CHARACTER_LIMIT_DESCRIPTION = 250

	/**props section */
	const { onCloseButtonClick, isSaving, classes, setToasterMessageConfig, surveyTemplate } = props

	const [surveyTemplateModel, setSurveyTemplateModel] = useState({
		description: '',
		name: '',
	})

	// /**Use effect section */
	useEffect(() => {
		if (surveyTemplate) {
			setSurveyTemplateModel({
				...surveyTemplateModel,
				description: surveyTemplate ? surveyTemplate.Description : '',
				name: surveyTemplate ? surveyTemplate.Title : '',
			})
		}
	}, [])

	const onEditSurveyTemplate = () => {
		return dispatch(updateSurveyTemplateFromBackEnd(surveyTemplate.Id, surveyTemplateModel))
			.then(() => {
				onCloseButtonClick()
				setToasterMessageConfig({
					showToaster: true,
					toasterVariant: 'success',
					toasterMessage: 'common.addCorrectly',
				})
			})
			.catch((response) => {
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage: !IsNullOrEmpty(response.data) ? response.data : 'common.errorToSave',
					toasterVariant: 'error',
					// name: biddingModel.name,
				})
			})
	}
	/**Verify if can be sabed data*/
	const canBeSaved = () => {
		if (surveyTemplateModel) {
			//if (IsNullOrEmpty(surveyTemplateModel.comments)) return false
			if (IsNullOrEmpty(surveyTemplateModel.description)) return false
			if (IsNullOrEmpty(surveyTemplateModel.name)) return false
			return true
		}
		return false
	}

	/**On change properties */
	const onPropertyChange = (event) => {
		let { name, value } = event.target

		setSurveyTemplateModel({
			...surveyTemplateModel,
			[name]: value,
		})
	}

	return (
		<LateralPanel onClose={onCloseButtonClick}>
			<PanelHeader
				titlePanel={
					<span>
						<Translate id='survey.template.editSurveyTemplate' />
					</span>
				}
				// subTitlePanel={`${translate('biddingsModule.table.folio')}`}
				onCloseButtonClick={onCloseButtonClick}
				icon={
					<Avatar className={classes.avatar}>
						<ViewCarouselIcon />
					</Avatar>
				}
				isIconMaterial={true}
			/>
			<div>
				<div className={classes.containerInformation}>
					<Grid container>
						<Grid item xs={12} className={classes.titleDisplay}>
							<Typography variant='subtitle1' color='primary' className={classes.margingTopText}>
								Captura la información necesaria para editar una encuesta.
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<TextField
								fullWidth
								label={<Translate id='survey.template.create.name' />}
								className={classes.textField}
								onChange={onPropertyChange}
								multiline
								name='name'
								margin='normal'
								variant='outlined'
								value={surveyTemplateModel ? surveyTemplateModel.name : 0}
								inputProps={{ maxLength: CHARACTER_LIMIT_NAME }}
								helperText={`${
									surveyTemplateModel ? surveyTemplateModel.name.length : 0
								}/${CHARACTER_LIMIT_NAME}`}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								fullWidth
								label={<Translate id='survey.template.create.description' />}
								className={classes.textField}
								onChange={onPropertyChange}
								multiline
								name='description'
								margin='normal'
								variant='outlined'
								value={surveyTemplateModel ? surveyTemplateModel.description : 0}
								inputProps={{ maxLength: CHARACTER_LIMIT_DESCRIPTION }}
								helperText={`${
									surveyTemplateModel ? surveyTemplateModel.description.length : 0
								}/${CHARACTER_LIMIT_DESCRIPTION}`}
							/>
						</Grid>
					</Grid>
				</div>
				<Grid container>
					<Grid item xs={12} className={classes.containerBtn}>
						<Button
							data-cy='btn-save'
							type='button'
							color='primary'
							variant='outlined'
							onClick={onEditSurveyTemplate}
							disabled={!canBeSaved() || isSaving}
						>
							{<Translate id='common.save' />}
						</Button>
					</Grid>
				</Grid>
			</div>
		</LateralPanel>
	)
}

const styles = (theme) => ({
	avatar: {
		backgroundColor: theme.palette.secondary.main,
	},
	expansionPanelSummary: {
		backgroundColor: BACKGROUND_COLOR_GRAY,
	},
	containerInformation: {
		maxHeight: `calc(100vh - ${155}px)`,
		minHeight: `calc(100vh - ${155}px)`,
		overflowY: 'auto',
		padding: '20px',
	},
	containerBtn: {
		display: 'flex',
		justifyContent: 'center',
	},
	titleDisplay: {
		display: 'flex',
	},
})

EditSurveyTemplatePanel.propTypes = {
	/**
	 * callback executed add new user in the list
	 */
	onSaveButtonClick: propTypes.func,
	/**
	 * open and closedlateral panel for add an new user in customer
	 */
	onCloseButtonClick: propTypes.func,
	/**
	 * Determinate if is saving data
	 */
	isSaving: propTypes.bool.isRequired,
}

EditSurveyTemplatePanel.defaultProps = {
	onSaveButtonClick: () => console.warn('Callback [onSaveButtonClick] no defined'),
	onCloseButtonClick: () => console.warn('Callback [onCloseButtonClick] no defined'),
	isSaving: false,
}

export default withRouter(withStyles(styles, { withTheme: true })(EditSurveyTemplatePanel))
