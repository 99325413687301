import React, { useState } from 'react'
import {
	Card,
	CardContent,
	Typography,
	Divider,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Avatar,
	Tabs,
	Tab,
} from '@material-ui/core'
import { CalendarToday, Person } from '@material-ui/icons'
// Importaciones de Material-UI
import { withStyles } from '@material-ui/core/styles'
// import PercentIcon from '@material-ui/icons/Percent';
// import SupplierCard from './SupplierCard'
// import SurveyCard from './SurveyCard'
import SupplierInSurvey from './SupplierInSurvey'
import ShowDesktopSurveyTemplate from '../../surveyTemplates/components/show/ShowDesktop'
const ShowDesktop = (props) => {
	const { survey } = props
	const [activeTab, setActiveTab] = useState(0)

	if (!survey) {
		return (
			<Typography variant='h6' color='textSecondary' align='center'>
				No hay información disponible.
			</Typography>
		)
	}

	const handleTabChange = (event, newValue) => {
		setActiveTab(newValue)
	}

	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				backgroundColor: '#f5f5f5',
				padding: 16,
			}}
		>
			<Card
				style={{
					width: '100%',
					borderRadius: 16,
					boxShadow: '0 4px 10px rgba(0,0,0,0.1)',
					backgroundColor: '#ffffff',
				}}
			>
				<CardContent>
					<Typography
						variant='h5'
						color='primary'
						align='center'
						style={{ fontWeight: 'bold', marginBottom: 16 }}
					>
						Detalles de la Encuesta
					</Typography>
					<Divider style={{ marginBottom: 24 }} />

					{/* Tabs Header */}
					<Tabs
						value={activeTab}
						onChange={handleTabChange}
						indicatorColor='primary'
						textColor='primary'
						centered
					>
						<Tab label='Información General' />
						<Tab label='Proveedores' />
						<Tab label='Proveedores excluidos' />
						<Tab label='Plantilla usada' />
					</Tabs>

					<Divider style={{ margin: '16px 0' }} />

					{/* Tabs Content */}
					{activeTab === 0 && (
						<List>
							<ListItem>
								<ListItemAvatar>
									<Avatar>
										<Person />
									</Avatar>
								</ListItemAvatar>
								<ListItemText primary='Nombre' secondary={survey.Name || 'No disponible'} />
							</ListItem>

							<ListItem>
								<ListItemAvatar>
									<Avatar>
										<CalendarToday />
									</Avatar>
								</ListItemAvatar>
								<ListItemText
									primary='Fecha de Creación'
									secondary={new Date(survey.CreationDate).toLocaleString() || 'No disponible'}
								/>
							</ListItem>

							<ListItem>
								<ListItemAvatar>
									<Avatar>
										<CalendarToday />
									</Avatar>
								</ListItemAvatar>
								<ListItemText
									primary='Fecha de Activación'
									secondary={new Date(survey.ActivationDate).toLocaleString() || 'No disponible'}
								/>
							</ListItem>

							<ListItem>
								<ListItemAvatar>
									<Avatar>
										<CalendarToday />
									</Avatar>
								</ListItemAvatar>
								<ListItemText
									primary='Fecha de Expiración'
									secondary={new Date(survey.ExpirationDate).toLocaleString() || 'No disponible'}
								/>
							</ListItem>
						</List>
					)}

					{activeTab === 1 && <SupplierInSurvey survey={survey} />}

					{activeTab === 2 && (
						<Typography variant='body1' color='textSecondary' align='center'>
							Proveedores excluidos
						</Typography>
					)}

					{activeTab === 3 && (
						<ShowDesktopSurveyTemplate
							surveyTemplate={survey ? survey.SurveyTemplate : null}
							canBeEditData={false}
						/>
					)}
					{activeTab === 4 && (
						<Typography variant='body1' color='textSecondary' align='center'>
							Contenido para la tercera sección.
						</Typography>
					)}
				</CardContent>
			</Card>
		</div>
	)
}

const styles = (theme) => ({})

export default withStyles(styles)(ShowDesktop)
