/** Helpers import section */
import axios from 'axios'
import {
	getAuthorizedRequestConfigurationUser,
	getAuthorizedRequestConfigurationUserFiles,
} from '../helpers/SessionHelper'

export const IS_SAVING = 'IS_SAVING'
export const IS_LOADING = 'IS_LOADING'
export const SET_SURVEY_LIST = 'SET_SURVEY_LIST'
export const ADD_SURVEY_TEMPLATE = 'ADD_SURVEY_TEMPLATE'
export const EDIT_SURVEY_TEMPLATE = 'EDIT_SURVEY_TEMPLATE'
export const ADD_SURVEY_SECTION = 'ADD_SURVEY_SECTION'
export const DELETE_SURVEY_SECTION = 'DELETE_SURVEY_SECTION'
export const SURVEY_DETAIL = 'SURVEY_DETAIL'
export const SET_AVAILABLE_SURVEYS_BY_SUPPLIER = 'SET_AVAILABLE_SURVEYS_BY_SUPPLIER'

export function setAvailableSurveysBySupplier(items) {
	return {
		type: SET_AVAILABLE_SURVEYS_BY_SUPPLIER,
		items: items,
	}
}


/**
 * Action start loading common data
 * @param {*} isLoading
 */
function isLoadingCommon(isLoading, identifier) {
	return {
		type: IS_LOADING,
		isLoading: isLoading,
		identifier: identifier,
	}
}

/**
 * Action start saving information
 * @param {*} isLoading
 */
function isSavingCommon(isSaving, identifier) {
	return {
		type: IS_SAVING,
		isSaving: isSaving,
		identifier: identifier,
	}
}

export function setSurveyList(data) {
	return {
		type: SET_SURVEY_LIST,
		data: data,
	}
}

export function addSurveyTemplate(data) {
	return {
		type: ADD_SURVEY_TEMPLATE,
		item: data,
	}
}

export function editSurveyTemplate(data) {
	return {
		type: EDIT_SURVEY_TEMPLATE,
		item: data,
	}
}

export function setSurveyById(data) {
	return {
		type: SURVEY_DETAIL,
		item: data,
	}
}

export function setAddSurveySection(item) {
	return {
		type: ADD_SURVEY_SECTION,
		item: item,
	}
}

export function setDeleteSurveySection(id) {
	return {
		type: DELETE_SURVEY_SECTION,
		id,
	}
}

export function loadSurveysPaginatedFromBackEnd(queryParams) {
	return function (dispatch, getState) {
		dispatch(isLoadingCommon(true, SET_SURVEY_LIST))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_ADMINISTRATION}/Surveys`

		if (queryParams && queryParams.query) endPoint = endPoint + `?query=${queryParams.query}`
		if (queryParams && queryParams.rowsPerPage) endPoint = endPoint + `&pageSize=${queryParams.rowsPerPage}`

		return axios
			.get(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					console.log('serverResponse.data', serverResponse.data)
					dispatch(setSurveyList(serverResponse.data))
					dispatch(isLoadingCommon(false, SET_SURVEY_LIST))
					return Promise.resolve(serverResponse.data)
				}
			})
			.catch((error) => {
				dispatch(isLoadingCommon(false, SET_SURVEY_LIST))
				console.error('Error getting survey', error.response)
				return Promise.reject(error.response.data.detail)
			})
	}
}

/**
 * Action to create new Ethic code
 *
 * @export
 * @param {*} surveyModel
 * @returns
 */
export function createSurveysData(surveyModel) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true))

		let configuration = getAuthorizedRequestConfigurationUserFiles(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_ADMINISTRATION}/Surveys`
		let selectedCompany = getState().profile.get('selectedCompany').toJS()
		let formData = new FormData()
		surveyModel.attachments.forEach((file) => {
			formData.append('file', file)
		})

		let command = {
			CompanyCode: selectedCompany.company_code,
			Name: surveyModel.name,
			Nationals: surveyModel.optionNationals,
			Foreigns: surveyModel.optionForeigns,
			SurveySuppliers: surveyModel.suppliers,
			ActivationDate: surveyModel.activationDate,
			ExpirationDate: surveyModel.expirationDate,
			SurveyTemplateId: surveyModel.surveyTemplateId,
		}
		command = JSON.stringify(command)

		formData.append('command', command)

		return axios
			.post(endPoint, formData, configuration)
			.then((response) => {
				if (response.status === 200) {
					dispatch(isSavingCommon(false))
					return Promise.resolve(response.data)
				} else {
					dispatch(isSavingCommon(false))
				}
			})
			.catch((error) => {
				console.error('Error create Surveys', error.response)
				dispatch(isSavingCommon(false))
				return Promise.reject(error.response.data.Message)
			})
	}
}

export function getSurveyByIdFromBackEnd(id) {
	return function (dispatch, getState) {
		dispatch(isLoadingCommon(true, SURVEY_DETAIL))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_ADMINISTRATION}/Surveys/${id}`

		return axios
			.get(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isLoadingCommon(false, SURVEY_DETAIL))
					dispatch(setSurveyById(serverResponse.data))
					return Promise.resolve(serverResponse.data)
				}
			})
			.catch((error) => {
				dispatch(isLoadingCommon(false, SURVEY_DETAIL))
				console.error('Error getting survey by id', error.response)
				return Promise.reject(error.response.data.detail)
			})
	}
}

export function getAvailableSurveysBySupplier() {
	return function (dispatch, getState) {
		dispatch(isLoadingCommon(true, SURVEY_DETAIL))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_ADMINISTRATION}/Surveys/bySupplier`
		let selectedCompany = getState().profile.get('selectedCompany').toJS()
		let supplier = getState().suppliers.get('supplier')
		let supplierCode = undefined
		let supplierName = undefined
		if (getState().oidc.user.profile && getState().oidc.user.profile.company_token) {
			supplierCode = getState().oidc.user.profile.company_code
		}
		if (supplier) {
			supplierName = supplier.get('name')
		}
		let command = {
			CompanyCode: selectedCompany.company_code,
			SupplierCode: supplierCode,
			SupplierName: supplierName,
		}

		return axios
			.post(endPoint, command, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isLoadingCommon(false, SURVEY_DETAIL))
					dispatch(setAvailableSurveysBySupplier(serverResponse.data))
					return Promise.resolve(serverResponse.data)
				}
			})
			.catch((error) => {
				dispatch(isLoadingCommon(false, SURVEY_DETAIL))
				console.error('Error getting survey by id', error.response)
				return Promise.reject(error.response.data.detail)
			})
	}
}
