import React from 'react'
// Importaciones de Material-UI
import { Typography, Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const EmptySections = (props) => {
	const { classes, section } = props
	//Functions
	const { onOpenCloseQuestionConfigPanel } = props

	return (
		<>
			{section && section.Questions&&section.Questions.length === 0 && (
				<div className={classes.emptyStateContainer}>
					<Typography variant='h6' color='textSecondary' align='center'>
						Aún no hay preguntas disponibles
					</Typography>
					<Typography variant='body2' color='textSecondary' align='center'>
						Comienza agregando la primera pregunta para esta sección.
					</Typography>
					<Button
						variant='contained'
						color='primary'
						size='medium'
						className={classes.addSectionButton}
						onClick={()=>onOpenCloseQuestionConfigPanel(section)}
					>
						Agregar Pregunta
					</Button>
				</div>
			)}
		</>
	)
}

const styles = (theme) => ({
	emptyStateContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '32px',
		backgroundColor: '#fff',
		borderRadius: '8px',
		boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
		marginTop: '16px',
	},
	addSectionButton: {
		marginTop: '16px',
		textTransform: 'none',
		fontSize: '1rem',
	},
})

export default withStyles(styles)(EmptySections)
