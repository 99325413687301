/** Helpers import section */
import axios from 'axios'
import { getAuthorizedRequestConfigurationUser } from '../helpers/SessionHelper'

export const IS_SAVING = 'IS_SAVING'
export const IS_LOADING = 'IS_LOADING'
export const SET_SURVEY_TEMPLATES_LIST = 'SET_SURVEY_TEMPLATES_LIST'
export const ADD_SURVEY_TEMPLATE = 'ADD_SURVEY_TEMPLATE'
export const EDIT_SURVEY_TEMPLATE = 'EDIT_SURVEY_TEMPLATE'
export const ADD_SURVEY_SECTION = 'ADD_SURVEY_SECTION'
export const DELETE_SURVEY_SECTION = 'DELETE_SURVEY_SECTION'
export const SURVEY_TEMPLATE_DETAIL = 'SURVEY_TEMPLATE_DETAIL'

/**
 * Action start loading common data
 * @param {*} isLoading
 */
function isLoadingCommon(isLoading, identifier) {
	return {
		type: IS_LOADING,
		isLoading: isLoading,
		identifier: identifier,
	}
}

/**
 * Action start saving information
 * @param {*} isLoading
 */
function isSavingCommon(isSaving, identifier) {
	return {
		type: IS_SAVING,
		isSaving: isSaving,
		identifier: identifier,
	}
}

export function setSurveyTemplates(data) {
	return {
		type: SET_SURVEY_TEMPLATES_LIST,
		data: data,
	}
}

export function addSurveyTemplate(data) {
	return {
		type: ADD_SURVEY_TEMPLATE,
		item: data,
	}
}

export function editSurveyTemplate(data) {
	return {
		type: EDIT_SURVEY_TEMPLATE,
		item: data,
	}
}



export function setSurveyTemplateById(data) {
	return {
		type: SURVEY_TEMPLATE_DETAIL,
		item: data,
	}
}

export function setAddSurveySection(item) {
	return {
		type: ADD_SURVEY_SECTION,
		item: item,
	}
}

export function setDeleteSurveySection(id) {
	return {
		type: DELETE_SURVEY_SECTION,
		id,
	}
}

export function loadSurveyTemplatesPaginatedFromBackEnd(queryParams) {
	return function (dispatch, getState) {
		dispatch(isLoadingCommon(true, SET_SURVEY_TEMPLATES_LIST))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_ADMINISTRATION}/SurveyTemplates`

		if (queryParams && queryParams.query) endPoint = endPoint + `?query=${queryParams.query}`
		if (queryParams && queryParams.pageNumber) endPoint = endPoint + `&pageNumber=${queryParams.pageNumber - 1}`
		if (queryParams && queryParams.rowsPerPage && queryParams.rowsPerPage !==1000) endPoint = endPoint + `&pageSize=${queryParams.rowsPerPage}`
		if (queryParams && queryParams.rowsPerPage &&queryParams.rowsPerPage ===1000) endPoint = endPoint + `?pageSize=${queryParams.rowsPerPage}`

		return axios
			.get(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					console.log('serverResponse.data', serverResponse.data)
					dispatch(setSurveyTemplates(serverResponse.data))
					dispatch(isLoadingCommon(false, SET_SURVEY_TEMPLATES_LIST))
					return Promise.resolve(serverResponse.data)
				}
			})
			.catch((error) => {
				dispatch(isLoadingCommon(false, SET_SURVEY_TEMPLATES_LIST))
				console.error('Error getting survey Templated', error.response)
				return Promise.reject(error.response.data.detail)
			})
	}
}

export function addSurveyTemplateFromBackEnd(surveyModel) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true, ADD_SURVEY_TEMPLATE))
		let selectedCompany = getState().profile.get('selectedCompany')
		if (selectedCompany) {
			let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
			let endPoint = `${process.env.REACT_APP_SIP_ADMINISTRATION}/SurveyTemplates`

			let command = {
				Name: surveyModel ? surveyModel.name : '',
				Description: surveyModel ? surveyModel.description : '',
			}
			return axios
				.post(endPoint, command, configuration)
				.then((serverResponse) => {
					if (serverResponse.status === 200) {
						dispatch(addSurveyTemplate(serverResponse.data))
						return Promise.resolve()
					}
				})
				.catch((error) => {
					dispatch(isSavingCommon(false, ADD_SURVEY_TEMPLATE))
					console.error('Error in add survey template', error.response)
					return Promise.reject(error.response.data.detail)
				})
		} else {
			dispatch(isSavingCommon(false, ADD_SURVEY_TEMPLATE))
		}
	}
}

export function updateSurveyTemplateFromBackEnd(id, surveyModel) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true, ADD_SURVEY_TEMPLATE))
		let selectedCompany = getState().profile.get('selectedCompany')
		if (selectedCompany) {
			let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
			let endPoint = `${process.env.REACT_APP_SIP_ADMINISTRATION}/SurveyTemplates`

			let command = {
				Id: id,
				Name: surveyModel ? surveyModel.name : '',
				Description: surveyModel ? surveyModel.description : '',
			}
			return axios
				.put(endPoint, command, configuration)
				.then((serverResponse) => {
					if (serverResponse.status === 200) {
						dispatch(editSurveyTemplate(serverResponse.data))
						return Promise.resolve()
					}
				})
				.catch((error) => {
					dispatch(isSavingCommon(false, ADD_SURVEY_TEMPLATE))
					console.error('Error in add survey template', error.response)
					return Promise.reject(error.response.data.detail)
				})
		} else {
			dispatch(isSavingCommon(false, ADD_SURVEY_TEMPLATE))
		}
	}
}

export function addSurveySectionFromBackEnd(surveyTemplateId, surveyModel) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true, ADD_SURVEY_SECTION))
		let selectedCompany = getState().profile.get('selectedCompany')
		if (selectedCompany) {
			let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
			let endPoint = `${process.env.REACT_APP_SIP_ADMINISTRATION}/SurveyTemplates/section`

			let command = {
				SurveyTemplateId: surveyTemplateId,
				Name: surveyModel ? surveyModel.name : '',
				Description: surveyModel ? surveyModel.description : '',
				TitleColor: surveyModel ? surveyModel.titleColor : '',
				BackgroundColor: surveyModel ? surveyModel.backgroundColor : '',
			}
			return axios
				.post(endPoint, command, configuration)
				.then((serverResponse) => {
					if (serverResponse.status === 200) {
						dispatch(setAddSurveySection(serverResponse.data))
						return Promise.resolve()
					}
				})
				.catch((error) => {
					dispatch(isSavingCommon(false, ADD_SURVEY_SECTION))
					console.error('Error in add survey section', error.response)
					return Promise.reject(error.response.data.detail)
				})
		} else {
			dispatch(isSavingCommon(false, ADD_SURVEY_SECTION))
		}
	}
}

export function updateSurveySectionFromBackEnd(surveySectionId, surveyModel) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true, ADD_SURVEY_SECTION))
		let selectedCompany = getState().profile.get('selectedCompany')
		if (selectedCompany) {
			let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
			let endPoint = `${process.env.REACT_APP_SIP_ADMINISTRATION}/SurveyTemplates/section`

			let command = {
				SurveySectionId: surveySectionId,
				Name: surveyModel ? surveyModel.name : '',
				Description: surveyModel ? surveyModel.description : '',
				TitleColor: surveyModel ? surveyModel.titleColor : '',
				BackgroundColor: surveyModel ? surveyModel.backgroundColor : '',
			}
			return axios
				.put(endPoint, command, configuration)
				.then((serverResponse) => {
					if (serverResponse.status === 200) {
						dispatch(setAddSurveySection(serverResponse.data))
						return Promise.resolve()
					}
				})
				.catch((error) => {
					dispatch(isSavingCommon(false, ADD_SURVEY_SECTION))
					console.error('Error in add survey section', error.response)
					return Promise.reject(error.response.data.detail)
				})
		} else {
			dispatch(isSavingCommon(false, ADD_SURVEY_SECTION))
		}
	}
}

export function deleteSurveySectionFromBackEnd(id) {
	return function (dispatch, getState) {
		dispatch(isLoadingCommon(true, DELETE_SURVEY_SECTION))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_ADMINISTRATION}/SurveyTemplates/section/${id}`

		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isLoadingCommon(false, DELETE_SURVEY_SECTION))
					dispatch(setDeleteSurveySection(id))
					return Promise.resolve(serverResponse.data)
				}
			})
			.catch((error) => {
				dispatch(isLoadingCommon(false, DELETE_SURVEY_SECTION))
				console.error('Error delete survey Template by id', error.response)
				return Promise.reject(error.response.data.detail)
			})
	}
}

export function getSurveyTemplateByIdFromBackEnd(id) {
	return function (dispatch, getState) {
		dispatch(isLoadingCommon(true, SURVEY_TEMPLATE_DETAIL))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_ADMINISTRATION}/SurveyTemplates/${id}`

		return axios
			.get(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isLoadingCommon(false, SURVEY_TEMPLATE_DETAIL))
					dispatch(setSurveyTemplateById(serverResponse.data))
					return Promise.resolve(serverResponse.data)
				}
			})
			.catch((error) => {
				dispatch(isLoadingCommon(false, SURVEY_TEMPLATE_DETAIL))
				console.error('Error getting survey Template by id', error.response)
				return Promise.reject(error.response.data.detail)
			})
	}
}

export function deleteSurveyTemplateByIdFromBackEnd(id) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true, ADD_SURVEY_SECTION))

		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_ADMINISTRATION}/SurveyTemplates/${id}`

		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isSavingCommon(false, ADD_SURVEY_SECTION))

					// dispatch(setSurveyTemplateById(serverResponse.data))
					return Promise.resolve(serverResponse.data)
				}
			})
			.catch((error) => {
				dispatch(isSavingCommon(false, ADD_SURVEY_SECTION))

				console.error('Error delete survey Template by id', error.response)
				return Promise.reject(error.response.data.detail)
			})
	}
}

export function addSurverQuestionFromBackEnd(surveySectionId, surveyModel) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true, ADD_SURVEY_SECTION))
		let selectedCompany = getState().profile.get('selectedCompany')
		if (selectedCompany) {
			let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
			let endPoint = `${process.env.REACT_APP_SIP_ADMINISTRATION}/SurveyTemplates/question`

			let command = {
				SurveySectionId: surveySectionId,
				Title: surveyModel ? surveyModel.title : '',
				Type: surveyModel ? surveyModel.type : 0,
				Percentage: surveyModel ? surveyModel.percentage : '',
				Recommendation: surveyModel ? surveyModel.recommendation : '',
				Warning: surveyModel ? surveyModel.warning : '',
			}
			return axios
				.post(endPoint, command, configuration)
				.then((serverResponse) => {
					if (serverResponse.status === 200) {
						// dispatch(setAddSurveySection(serverResponse.data))
						dispatch(isSavingCommon(false, ADD_SURVEY_SECTION))

						return Promise.resolve()
					}
				})
				.catch((error) => {
					dispatch(isSavingCommon(false, ADD_SURVEY_SECTION))
					console.error('Error in add survey section', error.response)
					return Promise.reject(error.response.data.detail)
				})
		} else {
			dispatch(isSavingCommon(false, ADD_SURVEY_SECTION))
		}
	}
}
export function updateSurverQuestionFromBackEnd(surveyQuestionId, surveyModel) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true, ADD_SURVEY_SECTION))
		let selectedCompany = getState().profile.get('selectedCompany')
		if (selectedCompany) {
			let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
			let endPoint = `${process.env.REACT_APP_SIP_ADMINISTRATION}/SurveyTemplates/question`

			let command = {
				SurveyQuestionId: surveyQuestionId,
				Title: surveyModel ? surveyModel.title : '',
				Type: surveyModel ? surveyModel.type : 0,
				Percentage: surveyModel ? surveyModel.percentage : '',
				Recommendation: surveyModel ? surveyModel.recommendation : '',
				Warning: surveyModel ? surveyModel.warning : '',
			}
			return axios
				.put(endPoint, command, configuration)
				.then((serverResponse) => {
					if (serverResponse.status === 200) {
						dispatch(isSavingCommon(false, ADD_SURVEY_SECTION))
						// dispatch(setAddSurveySection(serverResponse.data))
						return Promise.resolve()
					}
				})
				.catch((error) => {
					dispatch(isSavingCommon(false, ADD_SURVEY_SECTION))
					console.error('Error in add survey section', error.response)
					return Promise.reject(error.response.data.detail)
				})
		} else {
			dispatch(isSavingCommon(false, ADD_SURVEY_SECTION))
		}
	}
}

export function deleteQuestionFromBackEnd(id) {
	return function (dispatch, getState) {
		dispatch(isLoadingCommon(true, SURVEY_TEMPLATE_DETAIL))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_ADMINISTRATION}/SurveyTemplates/question/${id}`

		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isLoadingCommon(false, SURVEY_TEMPLATE_DETAIL))
					// dispatch(setSurveyTemplateById(serverResponse.data))
					return Promise.resolve(serverResponse.data)
				}
			})
			.catch((error) => {
				dispatch(isLoadingCommon(false, SURVEY_TEMPLATE_DETAIL))
				console.error('Error delete question by id', error.response)
				return Promise.reject(error.response.data.detail)
			})
	}
}

export function addQuestionOptionFromBackEnd(questionId, surveyModel) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true, ADD_SURVEY_SECTION))
		let selectedCompany = getState().profile.get('selectedCompany')
		if (selectedCompany) {
			let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
			let endPoint = `${process.env.REACT_APP_SIP_ADMINISTRATION}/SurveyTemplates/option`

			let command = {
				QuestionId: questionId,
				Title: surveyModel ? surveyModel.title : '',
				Percentage: surveyModel ? surveyModel.percentage : '',
				IsCorrect: surveyModel ? surveyModel.isCorrect : '',
			}
			return axios
				.post(endPoint, command, configuration)
				.then((serverResponse) => {
					if (serverResponse.status === 200) {
						dispatch(isSavingCommon(false, ADD_SURVEY_SECTION))

						// dispatch(setAddSurveySection(serverResponse.data))
						return Promise.resolve()
					}
				})
				.catch((error) => {
					dispatch(isSavingCommon(false, ADD_SURVEY_SECTION))
					console.error('Error in add survey section option', error.response)
					return Promise.reject(error.response.data.detail)
				})
		} else {
			dispatch(isSavingCommon(false, ADD_SURVEY_SECTION))
		}
	}
}

export function editQuestionOptionFromBackEnd(optionId, surveyModel) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true, ADD_SURVEY_SECTION))
		let selectedCompany = getState().profile.get('selectedCompany')
		if (selectedCompany) {
			let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
			let endPoint = `${process.env.REACT_APP_SIP_ADMINISTRATION}/SurveyTemplates/option`

			let command = {
				OptionId: optionId,
				Title: surveyModel ? surveyModel.title : '',
				Percentage: surveyModel ? surveyModel.percentage : '',
				IsCorrect: surveyModel ? surveyModel.isCorrect : '',
			}
			console.log('command', command)
			return axios
				.put(endPoint, command, configuration)
				.then((serverResponse) => {
					if (serverResponse.status === 200) {
						dispatch(isSavingCommon(false, ADD_SURVEY_SECTION))

						// dispatch(setAddSurveySection(serverResponse.data))
						return Promise.resolve()
					}
				})
				.catch((error) => {
					dispatch(isSavingCommon(false, ADD_SURVEY_SECTION))
					console.error('Error in edit survey section option', error.response)
					return Promise.reject(error.response.data.detail)
				})
		} else {
			dispatch(isSavingCommon(false, ADD_SURVEY_SECTION))
		}
	}
}

export function deleteOptionFromBackEnd(id) {
	return function (dispatch, getState) {
		dispatch(isLoadingCommon(true, SURVEY_TEMPLATE_DETAIL))
		let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
		let endPoint = `${process.env.REACT_APP_SIP_ADMINISTRATION}/SurveyTemplates/option/${id}`

		return axios
			.delete(endPoint, configuration)
			.then((serverResponse) => {
				if (serverResponse.status === 200) {
					dispatch(isLoadingCommon(false, SURVEY_TEMPLATE_DETAIL))
					// dispatch(setSurveyTemplateById(serverResponse.data))
					return Promise.resolve(serverResponse.data)
				}
			})
			.catch((error) => {
				dispatch(isLoadingCommon(false, SURVEY_TEMPLATE_DETAIL))
				console.error('Error delete option by id', error.response)
				return Promise.reject(error.response.data.detail)
			})
	}
}

export function updateQuestionOrderFromBackEnd(surveySectionId, surveyQuestionId, destinationIndex) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true, ADD_SURVEY_SECTION))
		let selectedCompany = getState().profile.get('selectedCompany')
		if (selectedCompany) {
			let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
			let endPoint = `${process.env.REACT_APP_SIP_ADMINISTRATION}/SurveyTemplates/question/order`

			let command = {
				SurveySectionId: surveySectionId,
				SurveyQuestionId: surveyQuestionId,
				DestinationIndex: destinationIndex+1,
			}
			return axios
				.put(endPoint, command, configuration)
				.then((serverResponse) => {
					if (serverResponse.status === 200) {
						dispatch(isSavingCommon(false, ADD_SURVEY_SECTION))
						return Promise.resolve()
					}
				})
				.catch((error) => {
					dispatch(isSavingCommon(false, ADD_SURVEY_SECTION))
					console.error('Error in update question order', error.response)
					return Promise.reject(error.response.data.detail)
				})
		} else {
			dispatch(isSavingCommon(false, ADD_SURVEY_SECTION))
		}
	}
}

export function updateSurveySectionOrderFromBackEnd(surveyTemplateId, surveySectionId, destinationIndex) {
	return function (dispatch, getState) {
		dispatch(isSavingCommon(true, ADD_SURVEY_SECTION))
		let selectedCompany = getState().profile.get('selectedCompany')
		if (selectedCompany) {
			let configuration = getAuthorizedRequestConfigurationUser(getState().oidc.user)
			let endPoint = `${process.env.REACT_APP_SIP_ADMINISTRATION}/SurveyTemplates/section/order`

			let command = {
				SurveySectionId: surveySectionId,
				SurveyTemplateId: surveyTemplateId,
				DestinationIndex: destinationIndex+1,
			}
			return axios
				.put(endPoint, command, configuration)
				.then((serverResponse) => {
					if (serverResponse.status === 200) {
						dispatch(isSavingCommon(false, ADD_SURVEY_SECTION))
						return Promise.resolve()
					}
				})
				.catch((error) => {
					dispatch(isSavingCommon(false, ADD_SURVEY_SECTION))
					console.error('Error in update question order', error.response)
					return Promise.reject(error.response.data.detail)
				})
		} else {
			dispatch(isSavingCommon(false, ADD_SURVEY_SECTION))
		}
	}
}

