import React, { useState } from 'react'
import { Translate } from 'react-localize-redux'
import { useSelector, useDispatch } from 'react-redux'
/** Material UI import section */
import {
	Typography,
	List,
	ListItemText,
	ListItem,
	ListItemSecondaryAction,
	Tooltip,
	withStyles,
	Fab,
	FormControlLabel,
	Switch,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/AddTwoTone'

/** Custom component import section */
/** Resources import section */
import { COLOR_CART_PORT } from '../../../../store/helpers/StatusColorConstants'
import { FerryCuponStatus } from '../../../../store/helpers/AppConstants'
import AddFerryCoupons from './AddFerryCoupons'
import FerryCuponCard from './FerryCuponCard'
import ConfirmPasswordDialog from '../../../common/ConfirmPasswordDialog'
import EditFerryCoupons from './EditFerryCoupons'
import { deleteFerryCupon, setApplyFerryCoupon } from '../../../../store/consignmentNote/consignmentNoteActions'

const ViewFerryCoupons = (props) => {
	const dispatch = useDispatch()

	//object props section
	const { classes, consignmentNote, canbeUpdateSupplier, canbeUpdatePlant } = props

	const cuponsFerry = useSelector((state) =>
		state.consignmentNote.get('cuponsFerry') ? state.consignmentNote.get('cuponsFerry').toJS() : []
	)
	const isSaving = useSelector((state) =>
		state.consignmentNote.get('isSaving') ? state.consignmentNote.get('isSaving') : false
	)

	const [openPanelAddCuponFerry, setOpenPanelAddCuponFerry] = useState(false)
	const [openPanelEditCuponFerry, setOpenPanelEditCuponFerry] = useState({
		item: null,
		open: false,
	})

	const [confirmPasswordConfigModal, setConfirmPasswordConfigModal] = useState({
		opened: false,
		status: FerryCuponStatus.DRAFT,
		item: null,
	})

	const onOpenPanelEditCuponFerry = (item) => {
		setOpenPanelEditCuponFerry({
			opened: !openPanelEditCuponFerry.opened,
			item: item,
		})
	}

	const onConfirmPassword = (item, status) => {
		setConfirmPasswordConfigModal({
			opened: !confirmPasswordConfigModal.opened,
			status: status,
			item: item,
		})
	}

	const onOpenAddCuponPayment = () => {
		setOpenPanelAddCuponFerry(!openPanelAddCuponFerry)
	}

	const onChangeStatusFromFerryCupon = () => {
		if (props.onChangeStatusFromFerryCupon) {
			props
				.onChangeStatusFromFerryCupon(confirmPasswordConfigModal.item, confirmPasswordConfigModal.status)
				.finally((_) => {
					onConfirmPassword(null, 1)
				})
		}
	}

	const onDeleteFerryCupon = (item) => {
		return dispatch(deleteFerryCupon(item.id))
	}

	const onSetApplyFerryCoupon = (event) => {
		console.log('event.target.checked', event.target.checked)
		return dispatch(setApplyFerryCoupon(consignmentNote.transportCode, event.target.checked))
	}

	console.log('consignmentNote', consignmentNote)

	return (
		<>
			<List>
				<ListItem divider style={{ backgroundColor: COLOR_CART_PORT }}>
					<ListItemText
						secondary={
							<Typography component='span' variant='body2' color='textPrimary'>
								<strong>{'Cupones Ferry'}</strong>
							</Typography>
						}
					/>
					{cuponsFerry.length !== 2 &&
						canbeUpdateSupplier &&
						consignmentNote &&
						consignmentNote.applyFerryCoupon &&
						consignmentNote.attachments.length !== 0 && (
							<ListItemSecondaryAction>
								<Tooltip title={'Agregar cupón de ferry'}>
									<Fab
										className={classes.marginIcon}
										size='small'
										color='primary'
										onClick={onOpenAddCuponPayment}
										disabled={isSaving}
									>
										<AddIcon />
									</Fab>
								</Tooltip>
							</ListItemSecondaryAction>
						)}

					{canbeUpdatePlant && (
						<ListItemSecondaryAction>
							<FormControlLabel
								control={
									<Switch
										color='primary'
										checked={consignmentNote.applyFerryCoupon}
										onChange={onSetApplyFerryCoupon}
										value='checkedA'
										disabled={isSaving}

									/>
								}
								label='Aplica cupón de Ferry'
							/>
						</ListItemSecondaryAction>
					)}
				</ListItem>
				{cuponsFerry && cuponsFerry.length === 0 && (
					<ListItem divider>
						<ListItemText
							secondary={
								<Typography component='span' variant='body2' color='textPrimary'>
									{/* <Translate id='consignmentNote.show.addFiles' /> */}
									Agrega un cupón de Ferry de ida y uno de vuelta.
								</Typography>
							}
						/>
					</ListItem>
				)}

				{cuponsFerry &&
					cuponsFerry.map((cuponFerry) => {
						return (
							<FerryCuponCard
								//Object
								cuponFerry={cuponFerry}
								//Function
								onOpenModalConfirmForChangeStatus={onConfirmPassword}
								onOpenPanelEditCuponFerry={onOpenPanelEditCuponFerry}
								onDeleteFerryCupon={onDeleteFerryCupon}
								//bool
								isSaving={isSaving}
								canbeUpdatePlant={canbeUpdatePlant}
							/>
						)
					})}
			</List>

			{openPanelAddCuponFerry && (
				<AddFerryCoupons
					//Objects
					consignmentNote={consignmentNote}
					//Function
					onClose={onOpenAddCuponPayment}
					//bool
					isSaving={isSaving}
				/>
			)}

			{openPanelEditCuponFerry && openPanelEditCuponFerry.opened && (
				<EditFerryCoupons
					//Objects
					consignmentNote={consignmentNote}
					cuponFerryData={openPanelEditCuponFerry.item}
					//Function
					onClose={onOpenPanelEditCuponFerry}
					//bool
					isSaving={isSaving}
				/>
			)}

			{confirmPasswordConfigModal && confirmPasswordConfigModal.opened && (
				<ConfirmPasswordDialog
					open={confirmPasswordConfigModal.opened}
					title={<Translate id='common.confirmOperation' />}
					message1={
						confirmPasswordConfigModal.status === FerryCuponStatus.ACCEPTED
							? 'Se enviará la información al proveedor de cupones para su seguimiento. Una vez enviada la información ya no se pueden realizar cambios'
							: 'Se rechazará el cupon de Ferry. Una vez cancelado el cupón ya no se pueden realizar cambios'
					}
					message2={<Translate id='common.enterPassword' />}
					message3={''}
					onClickButtonConfirm={onChangeStatusFromFerryCupon}
					onClose={onConfirmPassword}
					isSaving={isSaving}
					isVisibleComments={false}
					commentRequired={false}
				/>
			)}
		</>
	)
}

ViewFerryCoupons.propTypes = {}

const styles = (theme) => ({
	attachmentsContainer: {
		justifyContent: 'space-between',
		alignItems: 'center',
		paddingTop: 4,
		paddingLeft: theme.spacing.unit,
	},
	optionsContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		width: 80,
	},
	marginIcon: {
		marginLeft: '5px',
	},
})

export default withStyles(styles, { withTheme: true })(ViewFerryCoupons)
