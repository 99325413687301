import { fromJS } from 'immutable'

import * as indirectPriceQuoteWithoutCodeActions from './IndirectPriceQuoteWithoutCodeAction'
/**
 * contraActions initial state
 */
export const indirectPriceQuoteWithoutCodeActionInitialState = fromJS({
	isLoading: false,
	identifier: '',
	isSaving: false,
	indirectPriceQuoteWithoutCode: [],
	indirectPriceQuoteWithoutCodeDetail: null,
    indirectPriceQuoteSupplier:null,
	indirectPriceQuoteWithoutCodeCount: 0,
	sortCriteria: {
		by: 'FolioSIT',
		ascending: false,
	},
	page: 0,
	rowsPerPage: 10,
	searchQuery: '',

	toasterConfiguration: {
		showToaster: false,
		toasterMessage: null,
		toasterVariant: '',
		name: '',
	},
})

export default function indirectPriceQuoteWithoutCodeReducer(
	state = indirectPriceQuoteWithoutCodeActionInitialState,
	action
) {
	switch (action.type) {
		//#region REDUCER FOR INDIRECT QUALIFICATIONS
		case indirectPriceQuoteWithoutCodeActions.START_LOADING_INDIRECT_PRICE_QUOTE_WITHOUT_CODE: {
			return state.merge({
				isLoading: action.isLoading,
				identifier: action.identifier,
			})
		}
		case indirectPriceQuoteWithoutCodeActions.IS_SAVING: {
		    return state.merge({
		        isSaving: action.isSaving,
		        identifier: action.identifier,
		    })
		}
		case indirectPriceQuoteWithoutCodeActions.SET_INDIRECT_INDIRECT_PRICE_QUOTE_WITHOUT_CODE_LIST: {
			return state.merge({
				indirectPriceQuoteWithoutCode: fromJS(action.items),
				isLoading: false,
				identifier: '',
			})
		}
		case indirectPriceQuoteWithoutCodeActions.SET_INDIRECT_INDIRECT_PRICE_QUOTE_WITHOUT_CODE_DETAIL: {
			let item = fromJS(action.item)
			return state.merge({
				indirectPriceQuoteWithoutCodeDetail: item,
				isLoading: false,
				identifier: '',
			})
		}
        case indirectPriceQuoteWithoutCodeActions.SET_INDIRECT_INDIRECT_PRICE_QUOTE_SUPPLIER_DETAIL: {
			let item = fromJS(action.item)
			return state.merge({
				indirectPriceQuoteSupplier: item,
				isLoading: false,
				identifier: '',
			})
		}
		case indirectPriceQuoteWithoutCodeActions.SET_INDIRECT_INDIRECT_PRICE_QUOTE_SUPPLIER_ITEM:{
			return state.updateIn(['indirectPriceQuoteSupplier', 'Items'], allItems => updateItems(allItems, action.item.token, fromJS(action.item))).set('isSaving', false);
		}
		case indirectPriceQuoteWithoutCodeActions.SET_INDIRECT_PRICE_QUOTE_QUERY:{
			return state.merge({
				query: action.query,
			})
		}
		case indirectPriceQuoteWithoutCodeActions.SET_INDIRECT_PRICE_QUOTE_PAGINATION: {
			return state.merge({
				page: action.page,
				rowsPerPage: action.rowsPerPage,
			})
		}
		case indirectPriceQuoteWithoutCodeActions.SET_INDIRECT_PRICE_QUOTE_COUNT_PAGINATION: {
			return state.merge({
				indirectPriceQuoteWithoutCodeCount: action.count,
			})
		}
		default:
			return state
	}
}

const updateItems = (allItems, token, item) => {
    let indexOfItem = allItems.findIndex(d => d.get('token') === token);
    if (indexOfItem >= 0) {
        return allItems.setIn([indexOfItem],item);
    }
    return allItems
}