import React from 'react'
// Importaciones de Material-UI
import { RadioGroup, FormControlLabel, Chip, IconButton, Radio, Tooltip } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'

const SingleChoiceOptions = (props) => {
	//bool
	const { canBeEditData } = props
	const { question } = props
	//Functions
	const { onOpenCloseDeleteOptionConfigPanel, onOpenCloseEditOptionConfigPanel } = props

	return (
		<RadioGroup>
			{question.Options.map((option, optionIndex) => (
				<div
					key={optionIndex}
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
						marginBottom: '8px',
						backgroundColor: option.IsCorrect ? '#D6EAF8' : 'transparent', // Fondo normal para incorrectas
						padding: '8px', // Espaciado interno opcional
						borderRadius: '4px', // Bordes redondeados opcionales
					}}
				>
					<FormControlLabel value={option.Title} control={<Radio color='primary' />} label={option.Title} />
					<div style={{ display: 'flex', gap: '8px' }}>
						<Chip label={`${option.Percentage}%`} color='primary' size='small' />
						{canBeEditData && (
							<Tooltip title='Editar opción'>
								<IconButton
									color='primary'
									size='small'
									onClick={() => onOpenCloseEditOptionConfigPanel(option)}
								>
									<EditIcon />
								</IconButton>
							</Tooltip>
						)}
						{canBeEditData && (
							<Tooltip title='Eliminar opción'>
								<IconButton
									// color='secondary'
									size='small'
									onClick={() => onOpenCloseDeleteOptionConfigPanel(option)}
								>
									<DeleteIcon />
								</IconButton>
							</Tooltip>
						)}
					</div>
				</div>
			))}
		</RadioGroup>
	)
}

const styles = (theme) => ({
	emptyStateContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '32px',
		backgroundColor: '#fff',
		borderRadius: '8px',
		boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
		marginTop: '16px',
	},
	addSectionButton: {
		marginTop: '16px',
		textTransform: 'none',
		fontSize: '1rem',
	},
})

export default withStyles(styles)(SingleChoiceOptions)
