///Import react section
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'
import { Translate } from 'react-localize-redux'

/** Material-UI imports section */
/** Import component section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout'
import Toaster from '../common/Toaster'
/**Import actions section */

import { getAvailableSurveysBySupplier } from '../../store/survey/SurveyActions'
import LoadingPage from '../common/LoadingPage'
import ShowDesktopTemplates from '../surveyTemplates/components/show/ShowDesktop'
import { converListToArray } from '../../store/helpers/ListHelper'
import { Tab, Tabs } from '@material-ui/core'

const ShowSupplier = (props) => {
	/**Render hooks section */
	const dispatch = useDispatch()

	/**Redux store information section*/

	const isLoading = useSelector((state) => state.surveys.get('isLoading'))
	// const isSaving = useSelector((state) => state.surveys.get('isSaving'))
	/**Redux store information section*/
	const surveys = useSelector((state) =>
		state.surveys.get('surveysAvailable') ? converListToArray(state.surveys.get('surveysAvailable')) : []
	)
	const [selectedTab, setSelectedTab] = useState(0)
	/**Redux store information section*/
	const [toasterMessageConfig, setToasterMessageConfig] = useState({
		showToaster: false,
		toasterMessage: null,
		toasterVariant: '',
		name: '',
	})

	/**Use effect section */
	useEffect(() => {
		dispatch(getAvailableSurveysBySupplier())
	}, [dispatch])

	if (isLoading) return <LoadingPage />

    const handleChange = (event, newValue) => {
        setSelectedTab(newValue);
      };
      
	return (
		<>
			<Tabs value={selectedTab} onChange={handleChange} aria-label='survey tabs'>
				{surveys.map((survey, index) => (
					<Tab key={survey.Id} label={survey.Name} />
				))}
			</Tabs>
			{surveys.map((survey, index) => (
				<div
					key={survey.Id}
					role='tabpanel'
					hidden={selectedTab !== index}
					id={`tabpanel-${index}`}
					aria-labelledby={`tab-${index}`}
					sx={{ p: 3 }}
				>
					{selectedTab === index && (
						<ShowDesktopTemplates surveyTemplate={survey.SurveyTemplate} canBeEditData={false} />
					)}
				</div>
			))}
			<Toaster
				message={<Translate id={toasterMessageConfig.toasterMessage} />}
				open={toasterMessageConfig.showToaster}
				variant={toasterMessageConfig.toasterVariant}
				onClose={() => {
					setToasterMessageConfig({
						showToaster: false,
						toasterMessage: '',
						toasterVariant: '',
					})
				}}
			/>
		</>
	)
}

const styles = (theme) => ({})

export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme: true })(ShowSupplier)))
